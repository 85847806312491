import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import GeneralService from '../service/general'
import {PageTitle} from '../_metronic/layout/core'

const AddTenantPage = () => {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      name: '',
      adminEmailAddress: '',
      adminPassword: '',
      companyName: '',
      address: '',
      email: '',
      country: 'Turkiye',
      city: '',
      county: '',
      taxAdministration: '',
      taxNumber: '',
    },
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Ad zorunludur'),
      adminEmailAddress: Yup.string().email().required(''),
      adminPassword: Yup.string().required(''),
      companyName: Yup.string().required(''),
      address: Yup.string().required(''),
      email: Yup.string().required(''),
      country: Yup.string().required(''),
      city: Yup.string().required(''),
      county: Yup.string().required(''),
      taxAdministration: Yup.string().required(''),
      taxNumber: Yup.string().required(''),
    }),
    onSubmit: async (values) => {
      const payload = {
        tenant: {
          name: values?.name,
          adminEmailAddress: values?.adminEmailAddress,
          adminPassword: values?.adminPassword,
        },
        company: {
          name: values?.companyName,
          address: values?.address,
          email: values?.email,
          country: values?.country,
          city: values?.city,
          county: values?.county,
          taxAdministration: values?.taxAdministration,
          taxNumber: values?.taxNumber,
        },
      }
      await GeneralService.create('company/companyAndTenant', payload)
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Başarıyla oluşturuldu.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
      navigate('/tenant')
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>Yeni tenant ekle </PageTitle>
      <div className='card'>
        <div className='card-body p-4'>
          <button onClick={() => navigate('/tenant')} className='btn btn-primary'>
            Back
          </button>
          <div id='kt_account_profile_details' className='collapse show'>
            <form noValidate className='form'>
              <div className='card-body p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>Ad</label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Ad'
                          name='name'
                          value={formik.values.name}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Admin Email Address
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Admin Email Address'
                          name='adminEmailAddress'
                          value={formik.values.adminEmailAddress}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Admin Password
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Admin Password'
                          name='adminPassword'
                          value={formik.values.adminPassword}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='row mb-6 col-12 col-md-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                      Firma Adi
                    </label>

                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='Ad'
                            name='companyName'
                            value={formik.values.companyName}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6 col-12 col-md-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>İl</label>

                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='İl'
                            name='city'
                            value={formik.values.city}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6 col-12 col-md-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>E-Posta</label>

                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='E-Posta'
                            name='email'
                            value={formik.values.email}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6 col-12 col-md-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>İlçe</label>

                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='İlçe'
                            name='county'
                            value={formik.values.county}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6 col-12 col-md-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                      Vergi Dairesi
                    </label>

                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='Vergi Dairesi'
                            name='taxAdministration'
                            value={formik.values.taxAdministration}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6 col-12 col-md-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>Adres</label>

                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='Adres'
                            name='address'
                            value={formik.values.address}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6 col-12 col-md-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                      Vergi Numarası
                    </label>

                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='Vergi Numarası'
                            name='taxNumber'
                            value={formik.values.taxNumber}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className='row mb-6 col-12'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>county</label>

                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='county'
                            name='county'
                            value={formik.values.county}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button
                  disabled={!formik.isValid || formik.isSubmitting}
                  type='submit'
                  onClick={formik.handleSubmit}
                  className='btn  btn-primary '
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export {AddTenantPage}
