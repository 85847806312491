import axios from 'axios'
import qs from 'qs'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('userToken')}`,
  },
  paramsSerializer: {
    serialize(params) {
      return qs.stringify(params, {arrayFormat: 'brackets'})
    },
  },
})

const refreshAccessToken = async () => {
  try {
    const response = await axios.post(`https://api.photonce.com/connect/token`,{
      grant_type: 'refresh_token',
      refresh_token: localStorage.getItem('refreshToken'),
      client_id: 'Backend_App',
    },{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    localStorage.setItem('userToken', response?.data?.access_token)
    localStorage.setItem('refreshToken', response?.data?.refresh_token)

    return response.data.accessToken
  } catch (error) {
    localStorage.removeItem('userToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('user')
    window.location.href = '/login'

    return null
  }
}

instance.interceptors.request.use((request) => {
  // const currentUser =  JSON.parse(localStorage.getItem('user'));

  return {
    ...request,
    headers: {
      ...request.headers,
      'Access-Control-Allow-Origin': '*',
      // 'Content-Type': 'application/json',
    },
  }
})

instance.interceptors.response.use(
  function (response) {
    return response
  },
  async function (error) {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const newAccessToken = await refreshAccessToken()
      if (newAccessToken) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
        return instance(originalRequest)
      }
    }
    return Promise.reject(error)
  }
)

export default instance
