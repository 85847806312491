/* eslint-disable jsx-a11y/anchor-is-valid */
import { PageTitle } from '../_metronic/layout/core'
import DataTable from 'react-data-table-component-with-filter'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useMutation, useQuery } from 'react-query'
import { useEffect } from 'react'
import { usePagination } from '../hooks'
import { Loader } from '../components'
import PackageService from '../service/package'

const PackagePage = () => {

    const navigate = useNavigate()

    const { setTotalRows, page, perPage, totalRows, handlePerRowsChange, handlePageChange } =
        usePagination()
    const { data, isLoading, refetch } = useQuery(['package', page + perPage], async () =>
        PackageService.getAll({
            page,
            perPage,
        })
    )
    useEffect(() => {
        totalRows !== data?.totalCount && setTotalRows(data?.totalCount)
    }, [data?.totalCount])

    const mutation = useMutation(async (id) => await PackageService.remove(id))

    const columns = [
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
            filterable: true,
        },
        {
            name: 'User Count',
            selector: (row) => row.userCount,
            sortable: true,
            filterable: true,
        },
        {
            name: 'Tag Count',
            selector: (row) => row.tagCount,
            sortable: true,
            filterable: true,
        },
        {
            name: 'Flow Count',
            selector: (row) => row.flowCount,
            sortable: true,
            filterable: true,
        },
        {
            name: 'Device Count',
            selector: (row) => row.deviceCount,
            sortable: true,
            filterable: true,
        },
        {
            name: 'Dashboard Count',
            selector: (row) => row.dashboardCount,
            sortable: true,
            filterable: true
        },
        {
            name: 'Disc Space Gb',
            selector: (row) => row.discSpaceGb,
            sortable: true,
            filterable: true
        },
        {
            name: 'Yearly Price',
            selector: (row) => row.yearlyPrice,
            sortable: true,
            filterable: true
        },
        {
            name: 'İşlemler',
            sortable: false,
            filterable: false,
            cell: (row) => {
                return (
                    <>
                        <div>
                            <button
                                disabled={mutation.isLoading}
                                onClick={() => navigate(`/package/${row.id}`)}
                                className='btn btn-dark'
                            >
                                <i className='fa fa-pencil mx-2 '></i>
                            </button>
                        </div>
                        <div>
                            <button
                                disabled={mutation.isLoading}
                                onClick={async () => {
                                    await Swal.fire({
                                        title: 'Emin misiniz?',
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonText: 'Evet',
                                        cancelButtonText: 'Hayır',
                                    }).then(async (result) => {
                                        if (result.isConfirmed) {
                                            await mutation.mutateAsync(row.id)
                                            Swal.fire({
                                                title: 'Başarıyla Silindi!',
                                                text: 'Başarıyla silindi.',
                                                icon: 'success',
                                                showConfirmButton: false,
                                                timer: 1500,
                                            })
                                            refetch()
                                        }
                                    })
                                }}
                                className='btn btn-danger mx-4'
                            >
                                <i className='fa fa-trash mx-2 '></i>
                            </button>
                        </div>
                    </>
                )
            },
        },
    ]
    return (
        <>
            <PageTitle breadcrumbs={[]}>Package Yönetimi</PageTitle>
            <div className='card'>
                <div className='card-body p-4'>
                    {isLoading ? (
                        <div className='text-center mt-2 mb-2'>
                            <Loader />
                        </div>
                    ) : (
                        <>
                            <div className='table-page-header p-5 my-3'>
                                <div className='add-new-user '>
                                    <button onClick={() => navigate('/package/add')} className='btn btn-primary '>
                                        <i className='fa fa-plus mx-2 '></i> Yeni Package Ekle
                                    </button>
                                </div>
                            </div>

                            <DataTable
                                progressPending={isLoading}
                                progressComponent={<Loader />}
                                className='table-striped custom-filtered-table'
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                paginationDefaultPage={page}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                customStyles={{
                                    rows: {
                                        style: {
                                            cursor: 'pointer',
                                            backgroundColor: 'var(--table-bg)',
                                            '&:hover': {
                                                backgroundColor: '#f3f3f3',
                                            },
                                        },
                                    },
                                }}
                                columns={columns}
                                data={data?.items}
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export { PackagePage }
