/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../_metronic/layout/core'
import DataTable from 'react-data-table-component-with-filter'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import {useMutation} from 'react-query'
import {useEffect, useState} from 'react'
// import {usePagination} from '../hooks'
import {Loader} from '../components'
import GeneralService from '../service/general'

const TenantPage = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const fetchData = () => {
    setIsLoading(true)
    GeneralService.getAll('multi-tenancy/tenants', {
      SkipCount: 0,
      MaxResultCount: 100,
    }).then((res) => {
      console.log(res, 'res')
      setData(res)
      setIsLoading(false)
    })
  }
  useEffect(() => {
    fetchData()
  }, [])

  console.log(data)
  const mutation = useMutation(
    async (id) => await GeneralService.remove('multi-tenancy/tenants', id)
  )

  const columns = [
    {
      name: 'Ad',
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
    },
    {
      name: 'İşlemler',
      sortable: false,
      filterable: false,
      cell: (row) => {
        return (
          <>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={() => navigate(`/tenant/${row.id}`)}
                className='btn btn-dark'
              >
                <i className='fa fa-pencil mx-2 '></i>
              </button>
            </div>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={async () => {
                  await Swal.fire({
                    title: 'Emin misiniz?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Evet',
                    cancelButtonText: 'Hayır',
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await mutation.mutateAsync(row.id)
                      Swal.fire({
                        title: 'Başarıyla Silindi!',
                        text: 'Başarıyla silindi.',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                      })
                      fetchData()
                    }
                  })
                }}
                className='btn btn-danger mx-4'
              >
                <i className='fa fa-trash mx-2 '></i>
              </button>
            </div>
          </>
        )
      },
    },
  ]
  return (
    <>
      <PageTitle breadcrumbs={[]}>Tenant Yonetimi </PageTitle>
      <div className='card'>
        <div className='card-body p-4'>
          {isLoading ? (
            <div className='text-center mt-2 mb-2'>
              <Loader />
            </div>
          ) : (
            <>
              <div className='table-page-header p-5 my-3'>
                <div className='add-new-user '>
                  <button onClick={() => navigate('/tenant/add')} className='btn btn-primary '>
                    <i className='fa fa-plus mx-2 '></i> Yeni Tenant Ekle
                  </button>
                </div>
              </div>

              <DataTable
                progressPending={isLoading}
                progressComponent={<Loader />}
                className='table-striped custom-filtered-table'
                customStyles={{
                  rows: {
                    style: {
                      cursor: 'pointer',
                      backgroundColor: 'var(--table-bg)',
                      '&:hover': {
                        backgroundColor: '#f3f3f3',
                      },
                    },
                  },
                }}
                columns={columns}
                data={data?.items}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export {TenantPage}
