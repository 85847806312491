import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import GeneralService from '../service/general'
import {useQuery} from 'react-query'
import { PageTitle } from '../_metronic/layout/core'

const AddPackageDevicePage = () => {
  const navigate = useNavigate()
  const {data: brands} = useQuery('PackageDevicesBrand', async () =>
    GeneralService.getAll('PackageDevicesBrand', {
      page: 1,
      perPage: 1000,
    })
  )

  const {data:models} = useQuery('PackageDevicesBrandModel', async () =>
    GeneralService.getAll('PackageDevicesBrandModel', {
      page: 1,
      perPage: 1000,
    })
  )
  const {data: sourceTypes} = useQuery('SourceType', async () =>
    GeneralService.getAll('SourceType', {
      page: 1,
      perPage: 1000,
    })
  )
  const formik = useFormik({
    initialValues: {
      name: '',
      modelId: '',
      sourceTypeId: '',
      brandId: '',
    },
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      modelId: Yup.string().required('Provider zorunludur'),
      sourceTypeId: Yup.string().required('Provider zorunludur'),
      name: Yup.string().required('Ad zorunludur'),
    }),
    onSubmit: async (values) => {
      await GeneralService.create('PackageDevice', {
        modelId: values.modelId,
        sourceTypeId: values.sourceTypeId,
        name: values.name,
      })
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Başarıyla oluşturuldu.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
      navigate('/device')
    },
  })

  return (
    <>
          <PageTitle breadcrumbs={[]}>Package device ekle</PageTitle>

    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate('/device')} className='btn btn-primary'>
          Back
        </button>

        <div id='kt_account_profile_details' className='collapse show'>
          <form noValidate className='form'>
            <div className='card-body p-9'>

            <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Marka</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        value={formik.values.brandId}
                        onChange={formik.handleChange}
                        name='brandId'
                        className='form-select form-control-solid'
                      >
                        <option selected>Marka Seciniz</option>
                        {brands?.items?.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Model</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        value={formik.values.modelId}
                        onChange={formik.handleChange}
                        name='modelId'
                        className='form-select form-control-solid'
                      >
                        <option selected>Model</option>
                        {models?.items
                          ?.filter?.((m) => Number(m?.brandId) === Number(formik?.values?.brandId))
                          .map((item) => (
                            <option value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

             
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Source Type</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        value={formik.values.sourceTypeId}
                        onChange={formik.handleChange}
                        name='sourceTypeId'
                        className='form-select form-control-solid'
                      >
                        <option selected>Source Type</option>
                        {sourceTypes?.items?.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Ad</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Ad'
                        name='name'
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                disabled={!formik.isValid || formik.isSubmitting}
                type='submit'
                onClick={formik.handleSubmit}
                className='btn  btn-primary '
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    </>
  )
}

export {AddPackageDevicePage}
