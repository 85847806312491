import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import DataTypeService from '../service/dataType'

const AddNewDataTypePage = () => {
  const navigate = useNavigate()
 
  const formik = useFormik({
    initialValues: {
      name: '',
      feName: '',
    },
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required'),
      feName: Yup.string().required('FE Name is required'),
    }),
    onSubmit: async (values) => {
      await DataTypeService.create(values)
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Başarıyla oluşturuldu.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
      navigate('/data-type')
    },
  })

  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate(-1)} className='btn btn-primary'>
          Geri Dön
        </button>

        <div id='kt_account_profile_details' className='collapse show'>
          <form noValidate className='form'>
            <div className='card-body p-9'>
           
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  Data Type Adı
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Data Type Adı'
                        name='name'
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              
           
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  Fe Adı
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Fe Adı'
                        name='feName'
                        value={formik.values.feName}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                disabled={!formik.isValid || formik.isSubmitting}
                type='submit'
                onClick={formik.handleSubmit}
                className='btn  btn-primary '
              >
                Kaydet
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export {AddNewDataTypePage}
