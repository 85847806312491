import Swal from 'sweetalert2'
import {useNavigate, useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Loader} from '../components'
import SourceDataKeyService from '../service/sourceDataKey'
import DataTypeService from '../service/dataType'
import SourceTypeService from '../service/sourceType'
import {PageTitle} from '../_metronic/layout/core'
import GeneralService from '../service/general'

const EditSourceDataKeyValuePage = () => {
  const navigate = useNavigate()
  const params = useParams()

  const {data, isLoading} = useQuery(['source-datakey-value', params.id], async () =>
    GeneralService.get('SourceDataKeyValue', params.id)
  )
  const {data: sourceData} = useQuery(['source-data-key'], async () =>
    SourceDataKeyService.getAll({
      page: 1,
      perPage: 1000,
    })
  )

 
  const formik = useFormik({
    initialValues: {
      value: data?.value ?? '',
      sourceDataKeyId: data?.sourceDataKeyId ?? '',
    },
    enableReinitialize: true,
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      sourceDataKeyId: Yup.string().required('sourceDataKeyId is required'),
      value: Yup.string().required('Source Datakey name is required'),
    }),
    onSubmit: async (values) => {
      await GeneralService.update('SourceDataKeyValue',values, params.id)
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Source DataKey Value alanı başarıyla düzenlendi.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
    },
  })
  return (
    <>
      <PageTitle breadcrumbs={[]}>Source Data Key Value Duzenle</PageTitle>

      <div className='card'>
        <div className='card-body p-4'>
          <button onClick={() => navigate('/source-datakey-value')} className='btn btn-primary'>
            Back
          </button>
          {isLoading ? (
            <div className='card-body p-9'>
              <Loader />
            </div>
          ) : (
            <div id='kt_account_profile_details' className='collapse show'>
              <form noValidate className='form'>
                <div className='card-body p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label fw-bold fs-6'>
                      Source Data Key Seç
                    </label>

                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <select
                            value={formik.values.sourceDataKeyId}
                            onChange={formik.handleChange}
                            name='sourceDataKeyId'
                            className='form-select form-control-solid'
                          >
                            <option selected>Source Data Key</option>
                            {sourceData?.items?.map((item) => (
                              <option value={item.id}>{item.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>Value</label>

                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder='Value'
                            name='value'
                            value={formik.values.value}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <button
                    disabled={!formik.isValid || formik.isSubmitting}
                    type='submit'
                    onClick={formik.handleSubmit}
                    className='btn  btn-primary '
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export {EditSourceDataKeyValuePage}
