import axios from './index'

const getAll = async (params) => {
  try {
    const res = await axios.get('/sourceType', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}

const update = async (data, id) => {
  const res = await axios.put(`/SourceType/${id}`, data)
  return res?.data
}

const get = async (id) => {
  const res = await axios.get(`/SourceType/${id}`)
  return res?.data?.value
}

const remove = async (id) => {
  const res = await axios.delete(`/SourceType/${id}`)
  return res?.data
}

const create = async (data) => {
  const res = await axios.post(`/SourceType`, data)
  return res?.data
}

const SourceTypeService = {
  getAll,
  get,
  update,
  create,
  remove
}

export default SourceTypeService
