/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../_metronic/layout/core'
import DataTable from 'react-data-table-component-with-filter'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import {useMutation, useQuery} from 'react-query'
import {useEffect} from 'react'
import {usePagination} from '../hooks'
import {Loader} from '../components'
import GeneralService from '../service/general'

const CompanyDiscountPage = () => {
  const navigate = useNavigate()

  const {setTotalRows, page, perPage, totalRows, handlePerRowsChange, handlePageChange} =
    usePagination()
  const {data, isLoading, refetch} = useQuery(['CompanyDiscount', page + perPage], async () =>
    GeneralService.getAll('CompanyDiscount', {
      page,
      perPage,
    })
  )

  const {data: packages} = useQuery('Package', async () =>
    GeneralService.getAll('Package', {
      page: 1,
      perPage: 1000,
    })
  )

  const {data: tenants} = useQuery('Tenant', async () =>
    GeneralService.getAll('multi-tenancy/tenants', {
      page: 1,
      perPage: 1000,
    })
  )

  useEffect(() => {
    totalRows !== data?.totalCount && setTotalRows(data?.totalCount)
  }, [data?.totalCount])

  const mutation = useMutation(async (id) => await GeneralService.remove('CompanyDiscount', id))

  const columns = [
    {
      name: 'Start Date',
      selector: (row) => Intl.DateTimeFormat('tr-TR').format(new Date(row.startDate)),
      sortable: true,
      filterable: true,
    },

    {
      name: 'End Date',
      selector: (row) => Intl.DateTimeFormat('tr-TR').format(new Date(row.endDate)),
      sortable: true,
      filterable: true,
    },

    {
      name: 'Is Yearly?',
      selector: (row) => (row.isYearly ? 'Yes' : 'No'),
      sortable: true,
      filterable: true,
    },
    {
      name: 'Discount',
      selector: (row) => row.discount,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Package',
      selector: (row) => packages?.items?.find((p) => p.id === row.packageId)?.name,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Tenant',
      selector: (row) => tenants?.items?.find((p) => p.id === row.tenantId)?.name,
      sortable: true,
      filterable: true,
    },

    {
      name: 'İşlemler',
      sortable: false,
      filterable: false,
      cell: (row) => {
        return (
          <>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={() => navigate(`/companydiscount/${row.id}`)}
                className='btn btn-dark'
              >
                <i className='fa fa-pencil mx-2 '></i>
              </button>
            </div>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={async () => {
                  await Swal.fire({
                    title: 'Emin misiniz?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Evet',
                    cancelButtonText: 'Hayır',
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await mutation.mutateAsync(row.id)
                      Swal.fire({
                        title: 'Başarıyla Silindi!',
                        text: 'Başarıyla silindi.',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                      })
                      refetch()
                    }
                  })
                }}
                className='btn btn-danger mx-4'
              >
                <i className='fa fa-trash mx-2 '></i>
              </button>
            </div>
          </>
        )
      },
    },
  ]
  return (
    <>
      <PageTitle breadcrumbs={[]}>Company Discount Yönetimi</PageTitle>
      <div className='card'>
        <div className='card-body p-4'>
          {isLoading ? (
            <div className='text-center mt-2 mb-2'>
              <Loader />
            </div>
          ) : (
            <>
              <div className='table-page-header p-5 my-3'>
                <div className='add-new-user '>
                  <button
                    onClick={() => navigate('/companydiscount/add')}
                    className='btn btn-primary '
                  >
                    <i className='fa fa-plus mx-2 '></i> Yeni Ekle
                  </button>
                </div>
              </div>

              <DataTable
                progressPending={isLoading}
                progressComponent={<Loader />}
                className='table-striped custom-filtered-table'
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationDefaultPage={page}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={{
                  rows: {
                    style: {
                      cursor: 'pointer',
                      backgroundColor: 'var(--table-bg)',
                      '&:hover': {
                        backgroundColor: '#f3f3f3',
                      },
                    },
                  },
                }}
                columns={columns}
                data={data?.items}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export {CompanyDiscountPage}
