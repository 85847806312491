import Swal from 'sweetalert2'
import {useNavigate, useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Loader} from '../components'
import GeneralService from '../service/general'

const EditPaymentsPage = () => {
  const navigate = useNavigate()
  const params = useParams()

  const {data, isLoading} = useQuery(['Payment', params.id], async () =>
    GeneralService.get('Payment', params.id)
  )

  const {data: packages} = useQuery('Package', async () =>
    GeneralService.getAll('Package', {
      page: 1,
      perPage: 1000,
    })
  )

  const {data: tenants} = useQuery('Tenant', async () =>
    GeneralService.getAll('multi-tenancy/tenants', {
      page: 1,
      perPage: 1000,
    })
  )

  const formik = useFormik({
    initialValues: {
      tenantId: data?.tenantId || '',
      userId: data?.userId || '',
      packageId: data?.packageId || '',
      providerName: data?.providerName || '',
      amount: data?.amount || '',
      instalment: data?.instalment || '',
      paymentStatus: data?.paymentStatus || '',
      errorCode: data?.errorCode || '',
      errorMessage: data?.errorMessage || '',
      orderNumber: data?.orderNumber || '',
      transactionId: data?.transactionId || '',
      // "tenantId": null,
      // "userId": "3a131a7d-5b6e-2b67-afa1-ff63f4672c55",
      // "packageId": 1,
      // "providerName": "Iyzico",
      // "amount": 10.000000000000000000000000000,
      // "instalment": 1,
      // "paymentStatus": 1,
      // "errorCode": "",
      // "errorMessage": "",
      // "orderNumber": "123456789",
      // "transactionId": "123456789",
      // "id": 2
    },
    enableReinitialize: true,
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      startDate: Yup.string().required('Start Date is required'),
      endDate: Yup.string().required('End Date is required'),
      tenantId: Yup.string().required('Tenant Id is required'),
      packageId: Yup.string().required('Package Id is required'),
      discount: Yup.number().required('Discount is required'),
    }),
    onSubmit: async (values) => {
      await GeneralService.update('CompanyDiscount', values, params.id)
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Company Discount başarıyla düzenlendi.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
    },
  })
  console.log(formik.values)
  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate('/payments')} className='btn btn-primary'>
          Back
        </button>

        <div id='kt_account_profile_details' className='collapse show'>
          <form noValidate className='form'>
            <div className='card-body p-9'>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Tenant</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        value={formik.values.tenantId}
                        onChange={formik.handleChange}
                        disabled
                        name='tenantId'
                        className='form-select '
                      >
                        <option selected>Tenant</option>
                        {tenants?.items?.map((item) => (
                          <option selected={item.id === formik.values.tenantId} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Package</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        value={formik.values.packageId}
                        onChange={formik.handleChange}
                        name='packageId'
                        disabled
                        className='form-select '
                      >
                        <option selected>Tenant</option>

                        {packages?.items?.map((item) => (
                          <option selected={item.id === formik.values.packageId} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  Provider Name
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg  mb-3 mb-lg-0'
                        placeholder='Provider Name'
                        disabled
                        name='providerName'
                        value={formik.values.providerName}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Amount</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='number'
                        className='form-control form-control-lg  mb-3 mb-lg-0'
                        placeholder='Amount'
                        disabled
                        name='amount'
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Instalment</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='number'
                        className='form-control form-control-lg  mb-3 mb-lg-0'
                        placeholder='Instalment'
                        disabled
                        name='instalment'
                        value={formik.values.instalment}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  Payment Status
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        value={formik.values.paymentStatus}
                        onChange={formik.handleChange}
                        disabled
                        name='paymentStatus'
                        className='form-select '
                      >
                        <option value={0}> Failed </option>
                        <option value={1}> Success </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {formik.values.errorCode !== '' && (
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Error Code
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <div className='alert alert-danger' role='alert'>
                          {formik.values.errorCode}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {formik.values.errorMessage !== '' && (
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Error Message
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <div className='alert alert-danger' role='alert'>
                          {formik.values.errorMessage}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  Order Number
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg  mb-3 mb-lg-0'
                        placeholder='Order Number'
                        disabled
                        name='orderNumber'
                        value={formik.values.orderNumber}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  Transaction Id
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg  mb-3 mb-lg-0'
                        placeholder='Transaction Id'
                        disabled
                        name='transactionId'
                        value={formik.values.transactionId}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export {EditPaymentsPage}
