import axios from './index'

const getAll = async (params) => {
  try {
    const res = await axios.get('/Communication', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}
const remove = async (id) => {
  try {
    await axios.delete(`/Communication/${id}`)
  } catch (error) {}
}

const CommunicationService = {
  getAll,
  remove,
}

export default CommunicationService
