/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../_metronic/layout/core'
import DataTable from 'react-data-table-component-with-filter'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import {useMutation, useQuery} from 'react-query'
import {useCallback, useEffect, useState} from 'react'
import {usePagination} from '../hooks'
import {Loader} from '../components'
import GeneralService from '../service/general'
import {Modal} from 'react-bootstrap'
import {useDropzone} from 'react-dropzone'
import DeviceService from '../service/device'

const DeviceTagPage = () => {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [file, setFile] = useState(null)
  const [edgeDeviceId, setEdgeDeviceId] = useState(null)
  const [deviceId, setDeviceId] = useState(null)

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0])
  }, [])

  const [uploadLoading, setUploadLoading] = useState(false)

  const {isDragActive, getRootProps, getInputProps} = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
      'application/vnd.ms-excel': '.xls',
    },
  })

  const {data: edgeDevices} = useQuery(['edgedevices'], async () =>
    DeviceService.getAllEdgeDevices({
      page: 1,
      perPage: 1000,
    })
  )

  const {data: devices} = useQuery(
    ['devices', edgeDeviceId],
    async () =>
      !!edgeDeviceId &&
      DeviceService.getDeviceByEdgeDeviceId({
        id: edgeDeviceId,
      })
  )

  const handleSubmit = async (event) => {
    event.preventDefault()
    setUploadLoading(true)
    if (!file) {
      Swal.fire('Please select a file first!')
      setUploadLoading(false)

      return
    }
    try {
      const response = await GeneralService.create(
        `PackageDeviceTag/upload?packageDeviceId=${deviceId}`,
        {file},
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      Swal.fire('Başarılı', '', 'success')
      refetch()
      setShowModal(false)
      setUploadLoading(false)
    } catch (error) {
      setUploadLoading(false)
      if (error?.response?.data?.errors?.length > 0) {
        Swal.fire(
          `Hata`,
          `${error.response.data.errors.map((e) => e.message).join('<br/>')}`,
          'error'
        )
      } else {
        Swal.fire('Hata', '', 'error')
      }
    }
  }
  const {setTotalRows, page, perPage, totalRows, handlePerRowsChange, handlePageChange} =
    usePagination()
  const {data, isLoading, refetch} = useQuery(['PackageDeviceTag', page + perPage], async () =>
    GeneralService.getAll('PackageDeviceTag', {
      page,
      perPage,
    })
  )
  useEffect(() => {
    totalRows !== data?.totalCount && setTotalRows(data?.totalCount)
  }, [data?.totalCount])

  const mutation = useMutation(async (id) => await GeneralService.remove('PackageDeviceTag', id))

  const columns = [
    {
      name: 'Ad',
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Aciklama',
      selector: (row) => row.description,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Adres',
      selector: (row) => row.address,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Package Device',
      selector: (row) => row.packageDevice?.name,
      sortable: true,
      filterable: true,
    },
    {
      name: 'İşlemler',
      sortable: false,
      filterable: false,
      cell: (row) => {
        return (
          <>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={() => navigate(`/device-tag/${row.id}`)}
                className='btn btn-dark'
              >
                <i className='fa fa-pencil mx-2 '></i>
              </button>
            </div>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={async () => {
                  await Swal.fire({
                    title: 'Emin misiniz?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Evet',
                    cancelButtonText: 'Hayır',
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await mutation.mutateAsync(row.id)
                      Swal.fire({
                        title: 'Başarıyla Silindi!',
                        text: 'Başarıyla silindi.',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                      })
                      refetch()
                    }
                  })
                }}
                className='btn btn-danger mx-4'
              >
                <i className='fa fa-trash mx-2 '></i>
              </button>
            </div>
          </>
        )
      },
    },
  ]
  return (
    <>
      <PageTitle breadcrumbs={[]}>Tag Yonetimi </PageTitle>
      <div className='card'>
        <div className='card-body p-4'>
          {isLoading ? (
            <div className='text-center mt-2 mb-2'>
              <Loader />
            </div>
          ) : (
            <>
              <div className='table-page-header p-5 my-3'>
                <div className='add-new-user '>
                  <button onClick={() => navigate('/device-tag/add')} className='btn btn-primary '>
                    <i className='fa fa-plus mx-2 '></i> Yeni Tag Ekle
                  </button>
                </div>
                <div className='import-excel '>
                  <button onClick={() => setShowModal(true)} className='btn btn-primary '>
                    <i className='fa fa-plus mx-2 '></i> Excel ile İçeri Aktar
                  </button>
                </div>
              </div>

              <DataTable
                progressPending={isLoading}
                progressComponent={<Loader />}
                className='table-striped custom-filtered-table'
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationDefaultPage={page}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={{
                  rows: {
                    style: {
                      cursor: 'pointer',
                      backgroundColor: 'var(--table-bg)',
                      '&:hover': {
                        backgroundColor: '#f3f3f3',
                      },
                    },
                  },
                }}
                columns={columns}
                data={data}
              />
            </>
          )}
        </div>
        <Modal
          className='modal fade'
          id='kt_modal_select_location'
          data-backdrop='static'
          tabIndex={-1}
          role='dialog'
          show={showModal}
          dialogClassName='modal-xl'
        >
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                <span className='fw-bolder'>Excel Dosyası Seç</span>
              </h5>
            </div>
            <div className='modal-body'>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Edge Device</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        className='form-select'
                        onChange={(e) => {
                          setEdgeDeviceId(parseInt(e.target.value))
                        }}
                      >
                        <option value='' disabled selected>
                          Edge Device Seçiniz
                        </option>
                        {!!edgeDevices?.items &&
                          edgeDevices?.items?.map((item, index) => {
                            return (
                              <option value={item.id} key={index}>
                                {' '}
                                {item.name}{' '}
                              </option>
                            )
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  {' '}
                  Package Device
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        name='deviceId'
                        onChange={(e) => {
                          setDeviceId(parseInt(e.target.value))
                        }}
                        className='form-select '
                      >
                        <option value='' disabled selected>
                          Package Device Seçiniz
                        </option>
                        {!!devices &&
                          devices?.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <section className='container'>
                <div
                  {...getRootProps({className: 'dropzone'})}
                  style={{
                    backgroundColor: isDragActive ? '#3594e8' : '',
                    border:'none'
                  }}
                >
                  <input {...getInputProps()} />
                  {uploadLoading ? (
                    <Loader />
                  ) : file ? (
                    <p>{file.name}</p>
                  ) : (
                    <p>Dosya Yukle (.xls, .xlsx)</p>
                  )}
                </div>
              </section>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light-primary'
                onClick={() => setShowModal(false)}
                disabled={!edgeDeviceId || !deviceId || isLoading || !file || uploadLoading}
              >
                Kapat
              </button>
              <button
                id='submit'
                type='button'
                className='btn btn-primary'
                disabled={!edgeDeviceId || !deviceId || isLoading || !file || uploadLoading}
                onClick={handleSubmit}
                // onClick={async () => {
                //   setShowModal(false)
                //   await mutation.mutateAsync({
                //     id: selectedDevice.id,
                //     name: deviceName,
                //     licenceId: selectedDevice.licenceId,
                //     dataSimId: selectedDevice.dataSimId,
                //     deviceTypeId: selectedDevice.deviceTypeId,
                //     tenantId: JSON.parse(localStorage.getItem('user')).tenantId,
                //   })
                //   refetch()
                // }}
              >
                Kaydet
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}

export {DeviceTagPage}
