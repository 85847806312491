import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import GeneralService from '../service/general'
import {useQuery} from 'react-query'

const AddEdgeDevicePage = () => {
  const navigate = useNavigate()

  const {data: licences} = useQuery('Licence', async () =>
    GeneralService.getAll('Licence', {
      page: 1,
      perPage: 1000,
    })
  )
  const {data: tenants} = useQuery('Tenant', async () =>
    GeneralService.getAll('multi-tenancy/tenants', {
      SkipCount: 0,
      MaxResultCount: 100,
    })
  )
  const {data: dataSims} = useQuery('DataSim', async () =>
    GeneralService.getAll('DataSim', {
      page: 1,
      perPage: 1000,
    })
  )
  const {data: deviceTypes} = useQuery('DeviceType', async () =>
    GeneralService.getAll('DeviceType', {
      page: 1,
      perPage: 1000,
    })
  )

  const formik = useFormik({
    initialValues: {
      name: '',
      // licenceId: '',
      dataSimId: '',
      deviceTypeId: '',
      tenantId: '',
    },
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('zorunludur'),
      // licenceId: Yup.string().required('zorunludur'),
      deviceTypeId: Yup.string().required('zorunludur'),
      dataSimId: Yup.string().required('zorunludur'),
      tenantId: Yup.string().required('zorunludur'),
    }),
    onSubmit: async (values) => {
      await GeneralService.create(
        'EdgeDevice',
        {
          name: values.name,
          // licenceId: values.licenceId,
          dataSimId: values.dataSimId,
          deviceTypeId: values.deviceTypeId,
          tenantId: values.tenantId,
        }
      )
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Başarıyla oluşturuldu.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
      navigate('/edge-device')
    },
  })

  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate('/edge-device')} className='btn btn-primary'>
          Back
        </button>

        <div id='kt_account_profile_details' className='collapse show'>
          <form noValidate className='form'>
            <div className='card-body p-9'>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Ad</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Ad'
                        name='name'
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Lisans</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        value={formik.values.licenceId}
                        onChange={formik.handleChange}
                        name='licenceId'
                        className='form-select form-control-solid'
                      >
                        <option selected>Lisans</option>
                        {licences?.items?.map((item) => (
                          <option value={item.id}>{item.id}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Data Sim</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        value={formik.values.dataSimId}
                        onChange={formik.handleChange}
                        name='dataSimId'
                        className='form-select form-control-solid'
                      >
                        <option selected>Data Sim</option>
                        {dataSims?.items?.map((item) => (
                          <option value={item.id}>{item.phoneNumber}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Device Type</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        value={formik.values.deviceTypeId}
                        onChange={formik.handleChange}
                        name='deviceTypeId'
                        className='form-select form-control-solid'
                      >
                        <option selected>Device Type</option>
                        {deviceTypes?.items?.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Tenant</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        value={formik.values.tenantId}
                        onChange={formik.handleChange}
                        name='tenantId'
                        className='form-select form-control-solid'
                      >
                        <option selected>Tenant</option>
                        {tenants?.items?.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                disabled={!formik.isValid || formik.isSubmitting}
                type='submit'
                onClick={formik.handleSubmit}
                className='btn  btn-primary '
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export {AddEdgeDevicePage}
