import axios from './index'

const getAll = async (params) => {
  try {
    const res = await axios.get('/DataType', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}
const remove = async (id) => {
  const res = await axios.delete(`/DataType/${id}`)
  return res?.data
}
const create = async (data) => {
  const res = await axios.post(`/DataType`, data)
  return res?.data
}
const get = async (id) => {
  const res = await axios.get(`/DataType/${id}`)
  return res?.data?.value
}

const update = async (data, id) => {
  const res = await axios.put(`/DataType/${id}`, data)
  return res?.data
}
 

const DataTypeService = {
  getAll,
  remove,
  create,
  get,
  update,
}

export default DataTypeService
