import axios from './index'

const getAll = async (params) => {
  try {
    const res = await axios.get('/Subscribe',{
      params
    })

    return res?.data?.value
  } catch (error) {}
}

const SubscribeService = {
  getAll,
}

export default SubscribeService
