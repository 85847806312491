import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useQuery} from 'react-query'
import GeneralService from '../service/general'
import {useEffect, useState} from 'react'

const AddCompanyPackagesPage = () => {
  const navigate = useNavigate()
  const [packageList, setPackageList] = useState([])
  const [selectedPackage, setSelectedPackage] = useState({})
  const today = new Date().toISOString().split('T')[0] // YYYY-MM-DD formatında bugünün tarihi

  const formik = useFormik({
    initialValues: {
      startDate: today,
      endDate: '',
      tenantId: '',
      packageId: '',
      price: 0,
      paymentInterval: 0,
    },
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      startDate: Yup.string().required('Start Date is required'),
      tenantId: Yup.string().required('Tenant Id is required'),
      packageId: Yup.string().required('Package Id is required'),
      price: Yup.number().required('Price is required').min(0, 'Price must be greater than 0'),
      paymentInterval: Yup.string().required('Payment Interval is required'),
    }),
    onSubmit: async (values) => {
      values.endDate = values.startDate
      await GeneralService.create('CompanyPackageSubscribe', values)
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Company Discount başarıyla oluşturuldu.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
      navigate('/company-packages')
    },
  })

  const {data: packages} = useQuery('Package', async () =>
    GeneralService.getAll('Package', {
      page: 1,
      perPage: 1000,
    })
  )

  const {data: tenants} = useQuery('Tenant', async () =>
    GeneralService.getAll('multi-tenancy/tenants', {
      page: 1,
      perPage: 1000,
    })
  )

  useEffect(() => {
    const selectedTenantPackage = packages?.items?.filter(
      (item) => item.tenantId === formik.values.tenantId
    )
    setPackageList(selectedTenantPackage)
  }, [formik.values.tenantId, packages])

  useEffect(() => {
    const selectedPackages = packageList?.find((item) => item.tenantId === formik.values.tenantId)
    setSelectedPackage(selectedPackages || {})
    if (selectedPackage) {
      const price =
        formik.values.paymentInterval === 0
          ? selectedPackage.monthlyPrice
          : selectedPackage.yearlyPrice
      formik.setFieldValue('price', price)
    }
  }, [formik.values.packageId, formik.values.paymentInterval, packageList])

  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate('/company-packages')} className='btn btn-primary'>
          Back
        </button>

        <div id='kt_account_profile_details' className='collapse show'>
          <form noValidate className='form'>
            <div className='card-body p-9'>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Start Date</label>
                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='date'
                        className='form-control form-control-lg  mb-3 mb-lg-0'
                        placeholder='Start Date'
                        name='startDate'
                        value={formik.values.startDate}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Tenant</label>
                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        value={formik.values.tenantId}
                        onChange={formik.handleChange}
                        name='tenantId'
                        className='form-select '
                      >
                        <option selected>Tenant</option>
                        {tenants?.items?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Package</label>
                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        value={formik.values.packageId}
                        onChange={formik.handleChange}
                        name='packageId'
                        disabled={formik.values.tenantId === ''}
                        className='form-select '
                      >
                        <option selected>Package</option>
                        {packageList?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  Payment Interval
                </label>
                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        value={formik.values.paymentInterval}
                        onChange={formik.handleChange}
                        name='paymentInterval'
                        className='form-select '
                      >
                        <option value='0'>Monthly</option>
                        <option value='1'>Yearly</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Price</label>
                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='number'
                        className='form-control form-control-lg  mb-3 mb-lg-0'
                        placeholder='Price'
                        disabled={true}
                        name='price'
                        value={formik.values.price}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                disabled={!formik.isValid || formik.isSubmitting}
                type='submit'
                onClick={formik.handleSubmit}
                className='btn  btn-primary '
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export {AddCompanyPackagesPage}
