import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import GeneralService from '../service/general'
import {useQuery} from 'react-query'

const AddDataSimPage = () => {
  const navigate = useNavigate()
  const {data} = useQuery('categories', async () =>
    GeneralService.getAll('DataSimProvider', {
      page: 1,
      perPage: 1000,
    })
  )
  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      iccid: '',
      nmu: '',
      pin1: '',
      pin2: '',
      puk1: '',
      puk2: '',
      providerId: '',
    },
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string().required('Telefon Numarası zorunludur'),
      providerId: Yup.string().required('Provider zorunludur'),
      iccid: Yup.string().required('ICCID zorunludur'),
      nmu: Yup.string().required('NMU zorunludur'),
      pin1: Yup.string().required('PIN1 zorunludur'),
      pin2: Yup.string().required('PIN2 zorunludur'),
      puk1: Yup.string().required('PUK1 zorunludur'),
      puk2: Yup.string().required('PUK2 zorunludur'),
    }),
    onSubmit: async (values) => {
      await GeneralService.create('DataSim', values)
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Başarıyla oluşturuldu.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
      navigate('/data-sim')
    },
  })

  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate('/data-sim')} className='btn btn-primary'>
          Back
        </button>

        <div id='kt_account_profile_details' className='collapse show'>
          <form noValidate className='form'>
            <div className='card-body p-9'>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Provider</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        value={formik.values.providerId}
                        onChange={formik.handleChange}
                        name='providerId'
                        className='form-select form-control-solid'
                      >
                        <option selected>Provider</option>
                        {data?.items?.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  Telefon Numarası
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Telefon Numarası'
                        name='phoneNumber'
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>iccid</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='iccid'
                        name='iccid'
                        value={formik.values.iccid}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>nmu</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='nmu'
                        name='nmu'
                        value={formik.values.nmu}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Pin 1</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='pin1'
                        name='pin1'
                        value={formik.values.pin1}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Pin 2</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='pin2'
                        name='pin2'
                        value={formik.values.pin2}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Puk 1</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='puk1'
                        name='puk1'
                        value={formik.values.puk1}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

             

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Puk 2</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='puk2'
                        name='puk2'
                        value={formik.values.puk2}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

             



            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                disabled={!formik.isValid || formik.isSubmitting}
                type='submit'
                onClick={formik.handleSubmit}
                className='btn  btn-primary '
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export {AddDataSimPage}
