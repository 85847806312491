import React from 'react'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const menuData = [
    {
      to: '/dashboard',
      icon: 'home',
      title: 'Dashboard',
      fontIcon: 'bi-app-indicator',
    },
    {
      to: '',
      title: 'Edge Device Management',
      icon: 'element-11',
      fontIcon: 'bi-app-indicator',
      children: [
        {
          to: '/device-types',
          icon: 'gear',
          title: 'Device Types',
          fontIcon: 'bi-app-indicator',
        },
        {
          to: '/edge-device',
          icon: 'gear',
          title: 'Edge Device',
          fontIcon: 'bi-app-indicator',
        },
        {
          to: '/version-check',
          icon: 'gear',
          title: 'Version Check',
          fontIcon: 'bi-app-indicator',
        },
      ],
    },
    {
      to: '',
      title: 'Device Management',
      icon: 'element-11',
      fontIcon: 'bi-app-indicator',
      children: [
        {
          to: '/data-type',
          icon: 'gear',
          title: 'Data Type',
          fontIcon: 'bi-app-indicator',
        },
        {
          to: '/source-type',
          icon: 'gear',
          title: 'Source Type',
          fontIcon: 'bi-app-indicator',
        },
        {
          to: '/source-datakey',
          icon: 'gear',
          title: 'Source DataKey',
          fontIcon: 'bi-app-indicator',
        },
        {
          to: '/source-datakey-value',
          icon: 'gear',
          title: 'Source DataKeyValue',
          fontIcon: 'bi-app-indicator',
        },
      ],
    },

    {
      to: '',
      title: 'Package Management',
      icon: 'element-11',
      fontIcon: 'bi-app-indicator',
      children: [
        {
          to: '/package',
          icon: 'gear',
          title: 'Package',
          fontIcon: 'bi-app-indicator',
        },
        {
          to: '/companydiscount',
          title: 'Company Discount',
          icon: 'element-11',
          fontIcon: 'bi-app-indicator',
        },
        {
          to: '/company-packages',
          title: 'Company Package Subscribe',
          icon: 'element-11',
          fontIcon: 'bi-app-indicator',
        },
        {
          to: '/payments',
          title: 'Payments',
          icon: 'element-11',
          fontIcon: 'bi-app-indicator',
        },
        // {
        //   to: '/licence-period',
        //   icon: 'gear',
        //   title: 'Licence Period',
        //   fontIcon: 'bi-app-indicator',
        // },
        // {
        //   to: '/licence',
        //   icon: 'gear',
        //   title: 'Licence',
        //   fontIcon: 'bi-app-indicator',
        // },
      ],
    },

    {
      to: '',
      title: 'Data Sim Management',
      icon: 'element-11',
      fontIcon: 'bi-app-user',
      children: [
        {
          to: '/sim-provider',
          icon: 'gear',
          title: 'Data Sim Provider',
          fontIcon: 'bi-app-indicator',
        },
        {
          to: '/data-sim',
          icon: 'gear',
          title: 'Data Sim',
          fontIcon: 'bi-app-indicator',
        },
        {
          to: '/sim-reporter',
          icon: 'gear',
          title: 'Data Sim Reporter',
          fontIcon: 'bi-app-indicator',
        },
      ],
    },
    {
      to: '',
      title: 'Package Devices',
      icon: 'element-11',
      fontIcon: 'bi-app-indicator',
      children: [
        {
          to: '/device-brand',
          icon: 'gear',
          title: 'Brands',
          fontIcon: 'bi-app-indicator',
        },
        {
          to: '/brand-model',
          icon: 'gear',
          title: 'Brand Models',
          fontIcon: 'bi-app-indicator',
        },
        {
          to: '/device',
          icon: 'gear',
          title: 'Package Device',
          fontIcon: 'bi-app-indicator',
        },
        {
          to: '/device-tag',
          icon: 'gear',
          title: 'Package Device Tags',
          fontIcon: 'bi-app-indicator',
        },
      ],
    },

    {
      to: '/communication',
      title: 'Communications',
      icon: 'element-11',
      fontIcon: 'bi-app-indicator',
    },
    {
      to: '/subscribe',
      title: 'Subscribe',
      icon: 'element-11',
      fontIcon: 'bi-app-indicator',
    },
    {
      to: '/tenant',
      icon: 'gear',
      title: 'Tenant Management',
      fontIcon: 'bi-app-indicator',
    },
  ]
  return (
    <>
      {menuData.map((item, index) => {
        if (item?.children) {
          return (
            <SidebarMenuItemWithSub
              key={index}
              to={item.to}
              title={item.title}
              icon={item.icon}
              fontIcon={item.fontIcon}
            >
              {item.children.map((child, index) => (
                <SidebarMenuItem
                  key={index}
                  to={child.to}
                  icon={child.icon}
                  title={child.title}
                  fontIcon={child.fontIcon}
                />
              ))}
            </SidebarMenuItemWithSub>
          )
        } else {
          return (
            <SidebarMenuItem
              key={index}
              to={item.to}
              icon={item.icon}
              title={item.title}
              fontIcon={item.fontIcon}
            />
          )
        }
      })}
    </>
  )
}

export {SidebarMenuMain}
