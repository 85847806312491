/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../_metronic/layout/core'
import DataTable from 'react-data-table-component-with-filter'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import {useMutation, useQuery} from 'react-query'
import {useEffect} from 'react'
import {usePagination} from '../hooks'
import {Loader} from '../components'
import GeneralService from '../service/general'

const EdgeDevicePage = () => {
  const navigate = useNavigate()

  const {setTotalRows, page, perPage, totalRows, handlePerRowsChange, handlePageChange} =
    usePagination()
  const {data, isLoading, refetch} = useQuery(['EdgeDevice', page + perPage], async () =>
    GeneralService.getAll('EdgeDevice',{
      page,
      perPage,
    })
  )
  useEffect(() => {
    totalRows !== data?.totalCount && setTotalRows(data?.totalCount)
  }, [data?.totalCount])

  const mutation = useMutation(async (id) => await GeneralService.remove('EdgeDevice',id))

  const columns = [
    {
      name: 'Ad',
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Durumu',
      selector: (row) => row.deviceStatusName,
      sortable: true,
      filterable: true,
      cell: (row) => {
        const statusClass =
          row.deviceStatus === 1
            ? '   badge-light-primary'
            : row.deviceStatus === 2
            ? '   badge-light-warning'
            : row.deviceStatus === 3
            ? 'badge-light-dark'
            : row.deviceStatus === 4
            ? 'badge-light-success'
            : 'badge-light-danger'

        return <span className={`badge ${statusClass}`}>{row.deviceStatusName}</span>
      },
    },
    {
      name: 'Tenant',
      selector: (row) => row?.tenant?.name,
      sortable: true,
      filterable: true,
    },
    
    {
      name: 'İşlemler',
      sortable: false,
      filterable: false,
      cell: (row) => {
        return (
          <>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={() => navigate(`/edge-device/${row.id}`)}
                className='btn btn-dark'
              >
                <i className='fa fa-pencil mx-2 '></i>
              </button>
            </div>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={async () => {
                  await Swal.fire({
                    title: 'Emin misiniz?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Evet',
                    cancelButtonText: 'Hayır',
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await mutation.mutateAsync(row.id)
                      Swal.fire({
                        title: 'Başarıyla Silindi!',
                        text: 'Başarıyla silindi.',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                      })
                      refetch()
                    }
                  })
                }}
                className='btn btn-danger mx-4'
              >
                <i className='fa fa-trash mx-2 '></i>
              </button>
            </div>
          </>
        )
      },
    },
  ]
  return (
    <>
      <PageTitle breadcrumbs={[]}>Edge Device Yonetimi</PageTitle>
      <div className='card'>
        <div className='card-body p-4'>
          {isLoading ? (
            <div className='text-center mt-2 mb-2'>
              <Loader />
            </div>
          ) : (
            <>
              <div className='table-page-header p-5 my-3'>
                <div className='add-new-user '>
                  <button onClick={() => navigate('/edge-device/add')} className='btn btn-primary '>
                    <i className='fa fa-plus mx-2 '></i> Yeni Edge Device Ekle
                  </button>
                </div>
              </div>

              <DataTable
                progressPending={isLoading}
                progressComponent={<Loader />}
                className='table-striped custom-filtered-table'
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationDefaultPage={page}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={{
                  rows: {
                    style: {
                      cursor: 'pointer',
                      backgroundColor: 'var(--table-bg)',
                      '&:hover': {
                        backgroundColor: '#f3f3f3',
                      },
                    },
                  },
                }}
                columns={columns}
                data={data?.items}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export {EdgeDevicePage}
