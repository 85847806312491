/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../_metronic/layout/core'
import DataTable from 'react-data-table-component-with-filter'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import {useMutation, useQuery} from 'react-query'
import {useEffect} from 'react'
import {usePagination} from '../hooks'
import {Loader} from '../components'
import PackageService from '../service/package'
import GeneralService from '../service/general'

const PaymentsPage = () => {
  const navigate = useNavigate()

  const {setTotalRows, page, perPage, totalRows, handlePerRowsChange, handlePageChange} =
    usePagination()
  const {data, isLoading, refetch} = useQuery(['package', page + perPage], async () =>
    GeneralService.getAll('Payment', {
      page,
      perPage,
    })
  )
  useEffect(() => {
    totalRows !== data?.totalCount && setTotalRows(data?.totalCount)
  }, [data?.totalCount])
  const {data: packages} = useQuery('Package', async () =>
    GeneralService.getAll('Package', {
      page: 1,
      perPage: 1000,
    })
  )
  const {data: tenants} = useQuery('Tenant', async () =>
    GeneralService.getAll('multi-tenancy/tenants', {
      page: 1,
      perPage: 1000,
    })
  )
  const mutation = useMutation(async (id) => await PackageService.remove(id))

  const columns = [
    {
      name: 'Provider Name',
      selector: (row) => row.providerName,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Package',
      selector: (row) => packages?.items?.find((p) => p.id === row.packageId)?.name,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Tenant',
      selector: (row) => tenants?.items?.find((p) => p.id === row.tenantId)?.name,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Amount',
      selector: (row) => row.amount,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Payment Status',
      selector: (row) => row.paymentStatus,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Instalment',
      selector: (row) => row.instalment,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Order Number',
      selector: (row) => row.orderNumber,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Transaction Id',
      selector: (row) => row.transactionId,
      sortable: true,
      filterable: true,
    },

    {
      name: 'İşlemler',
      sortable: false,
      filterable: false,
      cell: (row) => {
        return (
          <>
            <div>
              <button
                disabled={mutation.isLoading}
                onClick={() => navigate(`/payments/${row.id}`)}
                className='btn btn-dark'
              >
                <i className='fa fa-pencil mx-2 '></i>
              </button>
            </div>
          </>
        )
      },
    },
  ]
  return (
    <>
      <PageTitle breadcrumbs={[]}>Payment Yönetimi</PageTitle>
      <div className='card'>
        <div className='card-body p-4'>
          {isLoading ? (
            <div className='text-center mt-2 mb-2'>
              <Loader />
            </div>
          ) : (
            <>
              <div className='table-page-header p-5 my-3'></div>

              <DataTable
                progressPending={isLoading}
                progressComponent={<Loader />}
                className='table-striped custom-filtered-table'
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationDefaultPage={page}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={{
                  rows: {
                    style: {
                      cursor: 'pointer',
                      backgroundColor: 'var(--table-bg)',
                      '&:hover': {
                        backgroundColor: '#f3f3f3',
                      },
                    },
                  },
                }}
                columns={columns}
                data={data?.items}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export {PaymentsPage}
