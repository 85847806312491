import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useQuery} from 'react-query'
import GeneralService from '../service/general'

const AddCompanyDiscountPage = () => {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      startDate: '',
      endDate: '',
      isYearly: false,
      tenantId: '',
      packageId: '',
      discount: 0,
    },
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      startDate: Yup.string().required('Start Date is required'),
      endDate: Yup.string().required('End Date is required'),
      tenantId: Yup.string().required('Tenant Id is required'),
      packageId: Yup.string().required('Package Id is required'),
      discount: Yup.number().required('Discount is required'),
    }),
    onSubmit: async (values) => {
      if (values.discount > 100) {
        Swal.fire({
          type: 'error',
          title: 'Başarısız',
          text: 'Discount can not be more than 100.',
          showConfirmButton: false,
          timer: 1500,
          icon: 'error',
        })
      } else {
        await GeneralService.create('CompanyDiscount', values)
        Swal.fire({
          type: 'success',
          title: 'Başarılı',
          text: 'Company Discount başarıyla oluşturuldu.',
          showConfirmButton: false,
          timer: 1500,
          icon: 'success',
        })
        navigate('/companydiscount')
      }
    },
  })

  const {data: packages} = useQuery('Package', async () =>
    GeneralService.getAll('Package', {
      page: 1,
      perPage: 1000,
    })
  )

  const {data: tenants} = useQuery('Tenant', async () =>
    GeneralService.getAll('multi-tenancy/tenants', {
      page: 1,
      perPage: 1000,
    })
  )
  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate('/companydiscount')} className='btn btn-primary'>
          Back
        </button>

        <div id='kt_account_profile_details' className='collapse show'>
          <form noValidate className='form'>
            <div className='card-body p-9'>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Is Yearly?</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        className='form-select form-select-lg'
                        name='isYearly'
                        value={formik.values.isYearly}
                        onChange={(e) => {
                          formik.setFieldValue('isYearly', e.target.value === 'true')
                        }}
                      >
                        <option value={'true'}>Yes</option>
                        <option value={'false'}>No</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Start Date</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='date'
                        className='form-control form-control-lg  mb-3 mb-lg-0'
                        placeholder='Start Date'
                        name='startDate'
                        value={formik.values.startDate}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>End Date</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='date'
                        className='form-control form-control-lg  mb-3 mb-lg-0'
                        placeholder='End Date'
                        min={formik.values.startDate}
                        name='endDate'
                        style={{
                          opacity: formik.values.startDate ? 1 : 0.5,
                        }}
                        disabled={!formik.values.startDate}
                        value={formik.values.endDate}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Tenant</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        value={formik.values.tenantId}
                        onChange={formik.handleChange}
                        name='tenantId'
                        className='form-select '
                      >
                        <option selected>Tenant</option>
                        {tenants?.items?.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Package</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        value={formik.values.packageId}
                        onChange={formik.handleChange}
                        name='packageId'
                        className='form-select '
                      >
                        <option selected>Package</option>
                        {packages?.items?.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Discount</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='number'
                        className='form-control form-control-lg  mb-3 mb-lg-0'
                        placeholder='Discount'
                        name='discount'
                        max={100}
                        value={formik.values.discount}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>{' '}
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                disabled={!formik.isValid || formik.isSubmitting}
                type='submit'
                onClick={formik.handleSubmit}
                className='btn  btn-primary '
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export {AddCompanyDiscountPage}
