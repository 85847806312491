import Swal from 'sweetalert2'
import {useNavigate, useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Loader} from '../components'
import GeneralService from '../service/general'

const EditPackageDeviceBrandPage = () => {
  const navigate = useNavigate()
  const params = useParams()
 
  const {data, isLoading} = useQuery(['simreporterdetail', params.id], async () =>
    GeneralService.get('PackageDevicesBrand', params.id)
  )

  const formik = useFormik({
    initialValues: {
      name:data?.name || '',
    },
    enableReinitialize: true,
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Ad zorunludur'),
    }),
    onSubmit: async (values) => {
      await GeneralService.update('PackageDevicesBrand', values, params.id)
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Başarıyla düzenlendi.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
    },
  })
  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate('/device-brand')} className='btn btn-primary'>
          Back
        </button>
        {isLoading ? (
          <div className='card-body p-9'>
            <Loader />
          </div>
        ) : (
          <div id='kt_account_profile_details' className='collapse show'>
            <form noValidate className='form'>
              <div className='card-body p-9'>
             
                <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  Ad
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Ad'
                        name='name'
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button
                  disabled={!formik.isValid || formik.isSubmitting}
                  type='submit'
                  onClick={formik.handleSubmit}
                  className='btn  btn-primary '
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  )
}

export {EditPackageDeviceBrandPage}
