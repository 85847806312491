import {lazy, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {
  AddNewCategoryPage,
  CategoryPage,
  EditCategoryPage,
  Dashboard,
  DataTypePage,
  AddNewDataTypePage,
  EditDataTypePage,
  SourceTypePage,
  SourceTypeAddPage,
  EditSourceTypePage,
  SourceDataKeyPage,
  AddSourceDataKeyPage,
  EditSourceDataKeyPage,
} from '../../pages'
import {PackagePage} from '../../pages/PackagePage'
import {AddPackagePage} from '../../pages/AddPackagePage'
import {DataSimPage} from '../../pages/DataSimPage'
import {EditDataSimPage} from '../../pages/EditDataSimPage'
import {AddDataSimPage} from '../../pages/AddDataSimPage'
import {DataSimProviderPage} from '../../pages/DataSimProviderPage'
import {AddDataSimProvider} from '../../pages/AddDataSimProvider'
import {EditDataSimProvider} from '../../pages/EditDataSimProvider'
import {DataSimReporter} from '../../pages/DataSimReporterPage'
import {AddDataSimReporterPage} from '../../pages/AddDataSimReporterPage'
import {EditDataSimReporterPage} from '../../pages/EditDataSimReporterPage'
import {LicencePage} from '../../pages/LicencePage'
import {AddLicencePage} from '../../pages/AddLicencePage'
import {EditLicencePage} from '../../pages/EditLicencePage'
import {PackageDeviceBrandPage} from '../../pages/PackageDeviceBrandPage'
import {AddPackageDeviceBrandPage} from '../../pages/AddPackageDeviceBrandPage'
import {EditPackageDeviceBrandPage} from '../../pages/EditPackageDeviceBrandPage'
import {BrandModelPage} from '../../pages/BrandModelPage'
import {EditBrandModelPage} from '../../pages/EditBrandModelPage'
import {PackageDevicePage} from '../../pages/PackageDevicePage'
import {AddPackageDevicePage} from '../../pages/AddPackageDevicePage'
import {EditPackageDevicePage} from '../../pages/EditPackageDevicePage'
import {DeviceTagPage} from '../../pages/DeviceTagPage'
import {AddDeviceTagPage} from '../../pages/AddDeviceTagPage'
import {EditDeviceTagPage} from '../../pages/EditDeviceTagPage'
import {CommunicationsPage} from '../../pages/CommunicationsPage'
import {SubscribePage} from '../../pages/SubscribePage'
import {AddPackageDeviceBrandModelPage} from '../../pages/AddPackageDeviceBrandModelPage'
import {DeviceTypesPage} from '../../pages/DeviceTypesPage'
import {AddDeviceTypePage} from '../../pages/AddDeviceTypePage'
import {EditDeviceTypePage} from '../../pages/EditDeviceTypePage'
import {LicencePeriodPage} from '../../pages/LicencePeriodPage'
import {AddLicencePeriodPage} from '../../pages/AddLicencePeriodPage'
import {EditLicencePeriodPage} from '../../pages/EditLicencePeriodPage'
import {TenantPage} from '../../pages/TenantPage'
import {AddTenantPage} from '../../pages/AddTenantPage'
import {EditTenantPage} from '../../pages/EditTenantPage'
import {EdgeDevicePage} from '../../pages/EdgeDevicePage'
import {AddEdgeDevicePage} from '../../pages/AddEdgeDevicePage'
import {EditEdgeDevicePage} from '../../pages/EditEdgeDevicePage'
import {VersionCheckPage} from '../../pages/VersionCheckPage'
import {AddVersionCheckPage} from '../../pages/AddVersionCheckPage'
import {SourceDataKeyValuePage} from '../../pages/SourceDataKeyValuePage'
import {AddSourceDataKeyValuePage} from '../../pages/AddSourceDataKeyValuePage'
import {EditSourceDataKeyValuePage} from '../../pages/EditSourceDataKeyValuePage'
import {CompanyDiscountPage} from '../../pages/CompanyDiscountPage'
import {AddCompanyDiscountPage} from '../../pages/AddCompanyDiscountPage'
import {EditCompanyDiscountPage} from '../../pages/EditCompanyDiscountPage'
import {PaymentsPage} from '../../pages/PaymentsPage'
import {EditPaymentsPage} from '../../pages/EditPaymentsPage'
import {EditPackagePage} from '../../pages/EditPackagePage'
import {CompanyPackagesPage} from '../../pages/CompanyPackagesPage'
import {AddCompanyPackagesPage} from '../../pages/AddCompanyPackagesPage'
import {EditCompanyPackagesPage} from '../../pages/EditCompanyPackagesPage'

const PrivateRoutes = () => {
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

  const routeData = [
    {
      path: 'dashboard',
      component: <Dashboard />,
    },
    {
      path: 'category/:id',
      component: <EditCategoryPage />,
    },
    {
      path: 'category',
      component: <CategoryPage />,
    },
    {
      path: 'category/add',
      component: <AddNewCategoryPage />,
    },
    {
      path: 'communication',
      component: <CommunicationsPage />,
    },
    {
      path: 'company-packages',
      component: <CompanyPackagesPage />,
    },
    {
      path: 'company-packages/add',
      component: <AddCompanyPackagesPage />,
    },
    {
      path: 'company-packages/:id',
      component: <EditCompanyPackagesPage />,
    },
    {
      path: 'subscribe',
      component: <SubscribePage />,
    },
    {
      path: 'data-type',
      component: <DataTypePage />,
    },
    {
      path: 'data-type/add',
      component: <AddNewDataTypePage />,
    },
    {
      path: 'data-type/:id',
      component: <EditDataTypePage />,
    },
    {
      path: 'source-type',
      component: <SourceTypePage />,
    },
    {
      path: 'source-type/add',
      component: <SourceTypeAddPage />,
    },
    {
      path: 'source-type/:id',
      component: <EditSourceTypePage />,
    },

    {
      path: 'source-datakey',
      component: <SourceDataKeyPage />,
    },
    {
      path: 'source-datakey/add',
      component: <AddSourceDataKeyPage />,
    },
    {
      path: 'source-datakey/:id',
      component: <EditSourceDataKeyPage />,
    },

    {
      path: 'source-datakey-value',
      component: <SourceDataKeyValuePage />,
    },
    {
      path: 'source-datakey-value/add',
      component: <AddSourceDataKeyValuePage />,
    },
    {
      path: 'source-datakey-value/:id',
      component: <EditSourceDataKeyValuePage />,
    },

    {
      path: 'package',
      component: <PackagePage />,
    },
    {
      path: 'package/add',
      component: <AddPackagePage />,
    },
    {
      path: 'package/:id',
      component: <EditPackagePage />,
    },

    {
      path: 'companydiscount',
      component: <CompanyDiscountPage />,
    },
    {
      path: 'companydiscount/add',
      component: <AddCompanyDiscountPage />,
    },
    {
      path: 'companydiscount/:id',
      component: <EditCompanyDiscountPage />,
    },
    {
      path: 'payments',
      component: <PaymentsPage />,
    },
    {
      path: 'payments/:id',
      component: <EditPaymentsPage />,
    },

    {
      path: 'data-sim',
      component: <DataSimPage />,
    },
    {
      path: 'data-sim/add',
      component: <AddDataSimPage />,
    },
    {
      path: 'data-sim/:id',
      component: <EditDataSimPage />,
    },

    {
      path: 'sim-provider',
      component: <DataSimProviderPage />,
    },

    {
      path: 'sim-provider/add',
      component: <AddDataSimProvider />,
    },

    {
      path: 'sim-provider/:id',
      component: <EditDataSimProvider />,
    },

    {
      path: 'sim-reporter',
      component: <DataSimReporter />,
    },

    {
      path: 'sim-reporter/add',
      component: <AddDataSimReporterPage />,
    },

    {
      path: 'sim-reporter/:id',
      component: <EditDataSimReporterPage />,
    },

    {
      path: 'licence',
      component: <LicencePage />,
    },
    {
      path: 'licence/add',
      component: <AddLicencePage />,
    },
    {
      path: 'licence/:id',
      component: <EditLicencePage />,
    },
    {
      path: 'device-brand',
      component: <PackageDeviceBrandPage />,
    },

    {
      path: 'device-brand/add',
      component: <AddPackageDeviceBrandPage />,
    },

    {
      path: 'device-brand/:id',
      component: <EditPackageDeviceBrandPage />,
    },

    {
      path: 'brand-model',
      component: <BrandModelPage />,
    },

    {
      path: 'brand-model/add',
      component: <AddPackageDeviceBrandModelPage />,
    },

    {
      path: 'brand-model/:id',
      component: <EditBrandModelPage />,
    },
    {
      path: 'device-types',
      component: <DeviceTypesPage />,
    },
    {
      path: 'device-types/add',
      component: <AddDeviceTypePage />,
    },
    {
      path: 'device-types/:id',
      component: <EditDeviceTypePage />,
    },
    {
      path: 'licence-period',
      component: <LicencePeriodPage />,
    },
    {
      path: 'licence-period/add',
      component: <AddLicencePeriodPage />,
    },
    {
      path: 'licence-period/:id',
      component: <EditLicencePeriodPage />,
    },
    {
      path: 'device',
      component: <PackageDevicePage />,
    },

    {
      path: 'device/add',
      component: <AddPackageDevicePage />,
    },

    {
      path: 'device/:id',
      component: <EditPackageDevicePage />,
    },

    {
      path: 'device-tag',
      component: <DeviceTagPage />,
    },

    {
      path: 'device-tag/add',
      component: <AddDeviceTagPage />,
    },

    {
      path: 'device-tag/:id',
      component: <EditDeviceTagPage />,
    },

    {
      path: 'tenant',
      component: <TenantPage />,
    },

    {
      path: 'tenant/add',
      component: <AddTenantPage />,
    },

    {
      path: 'tenant/:id',
      component: <EditTenantPage />,
    },

    {
      path: 'edge-device',
      component: <EdgeDevicePage />,
    },

    {
      path: 'edge-device/add',
      component: <AddEdgeDevicePage />,
    },

    {
      path: 'edge-device/:id',
      component: <EditEdgeDevicePage />,
    },

    {
      path: 'version-check',
      component: <VersionCheckPage />,
    },

    {
      path: 'version-check/add',
      component: <AddVersionCheckPage />,
    },
  ]

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        {routeData.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={<SuspensedView>{route.component}</SuspensedView>}
          />
        ))}

        {/* Lazy Modules */}
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      0: baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
