/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../_metronic/layout/core'
import DataTable from 'react-data-table-component-with-filter'
import Swal from 'sweetalert2'
import {useMutation, useQuery} from 'react-query'
import {useEffect, useState} from 'react'
import {usePagination} from '../hooks'
import {Loader} from '../components'
import CommunicationService from '../service/communication'
import {Modal} from 'react-bootstrap'

const CommunicationsPage = () => {
  const [selectedItem, setSelectedItem] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const {setTotalRows, page, perPage, totalRows, handlePerRowsChange, handlePageChange} =
    usePagination()
  const {data, isLoading, refetch} = useQuery(['Communication', page, perPage], async () =>
    CommunicationService.getAll({
      page,
      perPage,
    })
  )

  useEffect(() => {
    totalRows !== data?.totalCount && setTotalRows(data?.totalCount)
  }, [data?.totalCount])

  const mutation = useMutation(async (id) => await CommunicationService.remove(id))

  const columns = [
    {
      name: 'Adı',
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Telefon',
      selector: (row) => row.phoneNumber,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Mesaj',
      selector: (row) => row.message,
      sortable: true,
      filterable: true,
    },
    {
      name: 'İşlemler',
      sortable: false,
      filterable: false,
      cell: (row) => {
        return (
          <>
            <div className='d-flex'>
              <button
                disabled={mutation.isLoading}
                onClick={async () => {
                  setSelectedItem(row)
                  setShowModal(true)
                }}
                className='btn btn-warning mx-4'
              >
                <i className='fa fa-eye mx-2 '></i>
              </button>

              <button
                disabled={mutation.isLoading}
                onClick={async () => {
                  await Swal.fire({
                    title: 'Emin misiniz?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Evet',
                    cancelButtonText: 'Hayır',
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await mutation.mutateAsync(row.id)
                      Swal.fire({
                        title: 'Başarıyla Cozuldu!',
                        text: 'Başarıyla Cozuldu.',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                      })
                      refetch()
                    }
                  })
                }}
                className='btn btn-success mx-4'
              >
                <i className='fa fa-check mx-2 '></i>
              </button>
            </div>
          </>
        )
      },
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={[]}>Mesajlar Yönetimi</PageTitle>
      <Modal show={showModal} onBackdropClick={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>{selectedItem?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-12'>
              <p>
                <strong>Email: </strong>
                {selectedItem?.email}
              </p>
            </div>
            <div className='col-12'>
              <p>
                <strong>Telefon: </strong>
                {selectedItem?.phoneNumber}
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <p>
                <strong>Mesaj: </strong>
                {selectedItem?.message}
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={async () => {
              await Swal.fire({
                title: 'Emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet',
                cancelButtonText: 'Hayır',
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await mutation.mutateAsync(selectedItem.id)
                  Swal.fire({
                    title: 'Başarıyla Cozuldu!',
                    text: 'Başarıyla Cozuldu.',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  setShowModal(false)
                  setSelectedItem(null)
                  refetch()
                }
              })
            }}
            className='btn btn-primary'
          >
            Cozumle
          </button>
          <button
            onClick={() => {
              setShowModal(false)
              setSelectedItem(null)
            }}
            className='btn btn-secondary'
          >
            Kapat
          </button>
        </Modal.Footer>
      </Modal>
      <div className='card'>
        <div className='card-body p-4'>
          {isLoading ? (
            <div className='text-center mt-2 mb-2'>
              <Loader />
            </div>
          ) : (
            <>
              {/* <div className='table-page-header p-5 my-3'>
                <div className='add-new-user '>
                  <button onClick={() => navigate('/data-type/add')} className='btn btn-primary '>
                    <i className='fa fa-plus mx-2 '></i> Yeni Data Type Ekle
                  </button>
                </div>
              </div> */}

              <DataTable
                progressPending={isLoading}
                progressComponent={<Loader />}
                className='table-striped custom-filtered-table'
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationDefaultPage={page}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={{
                  rows: {
                    style: {
                      cursor: 'pointer',
                      backgroundColor: 'var(--table-bg)',
                      '&:hover': {
                        backgroundColor: '#f3f3f3',
                      },
                    },
                  },
                }}
                columns={columns}
                data={data?.items}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export {CommunicationsPage}
