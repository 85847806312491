import Swal from 'sweetalert2'
import {useNavigate, useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Loader} from '../components'
import GeneralService from '../service/general'
import {useEffect, useState} from 'react'

const EditCompanyPackagesPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [packageList, setPackageList] = useState([])
  const [selectedPackage, setSelectedPackage] = useState({})
  const {data, isLoading} = useQuery(['package', params.id], async () =>
    GeneralService.get('CompanyPackageSubscribe', params.id)
  )

  const {data: packages} = useQuery('Package', async () =>
    GeneralService.getAll('Package', {
      page: 1,
      perPage: 1000,
    })
  )

  const {data: tenants} = useQuery('Tenant', async () =>
    GeneralService.getAll('multi-tenancy/tenants', {
      page: 1,
      perPage: 1000,
    })
  )

  const formik = useFormik({
    initialValues: {
      startDate: data?.startDate.split('T')[0] ?? '',
      endDate: data?.endDate.split('T')[0] ?? '',
      tenantId: data?.tenantId ?? '',
      packageId: data?.packageId ?? '',
      price: data?.price ?? 0,
      paymentInterval: data?.paymentInterval ?? 0,
    },
    enableReinitialize: true,
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      startDate: Yup.string().required('Start Date is required'),
      endDate: Yup.string().required('End Date is required'),
      tenantId: Yup.string().required('Tenant Id is required'),
      packageId: Yup.string().required('Package Id is required'),
      paymentInterval: Yup.number().required('paymentInterval is required'),
      price: Yup.number().required('price is required'),
    }),
    onSubmit: async (values) => {
      await GeneralService.update('CompanyPackageSubscribe', values, params.id)
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Company Package Subscribe başarıyla düzenlendi.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
    },
  })
  useEffect(() => {
    const selectedTenantPackage = packages?.items?.filter(
      (item) => item.tenantId === formik.values.tenantId
    )
    console.log(selectedTenantPackage)
    setPackageList(selectedTenantPackage)
  }, [formik.values.tenantId, packages])

  useEffect(() => {
    const selectedPackages = packageList?.find((item) => item.tenantId === formik.values.tenantId)
    setSelectedPackage(selectedPackages || {})
    console.log(selectedPackage)
    console.log(formik.values)
    if (selectedPackage) {
      const price =
        parseInt(formik.values.paymentInterval) === 0
          ? selectedPackage.monthlyPrice
          : selectedPackage.yearlyPrice
      console.log(price)
      formik.setFieldValue('price', price)
    }
  }, [formik.values.packageId, formik.values.paymentInterval, packageList])
  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate('/company-packages')} className='btn btn-primary'>
          Back
        </button>
        {isLoading ? (
          <div className='card-body p-9'>
            <Loader />
          </div>
        ) : (
          <div id='kt_account_profile_details' className='collapse show'>
            <form noValidate className='form'>
              <div className='card-body p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Start Date
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='date'
                          className='form-control form-control-lg  mb-3 mb-lg-0'
                          placeholder='Start Date'
                          name='startDate'
                          value={formik.values.startDate}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>End Date</label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='date'
                          className='form-control form-control-lg  mb-3 mb-lg-0'
                          placeholder='End Date'
                          min={formik.values.startDate}
                          disabled={true}
                          name='endDate'
                          style={{
                            opacity: formik.values.startDate ? 1 : 0.5,
                          }}
                          value={formik.values.endDate}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>Tenant</label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <select
                          value={formik.values.tenantId}
                          onChange={formik.handleChange}
                          name='tenantId'
                          className='form-select '
                        >
                          <option selected value={''}>
                            Tenant
                          </option>
                          {tenants?.items?.map((item) => (
                            <option selected={item.id === formik.values.tenantId} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>Package</label>
                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <select
                          value={formik.values.packageId}
                          onChange={formik.handleChange}
                          name='packageId'
                          disabled={formik.values.tenantId === ''}
                          className='form-select '
                        >
                          <option selected>Package</option>
                          {packageList?.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Payment Interval
                  </label>
                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <select
                          value={formik.values.paymentInterval}
                          onChange={formik.handleChange}
                          name='paymentInterval'
                          className='form-select '
                        >
                          <option value={0}>Monthly</option>
                          <option value={1}>Yearly</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>Price</label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg  mb-3 mb-lg-0'
                          placeholder='Price'
                          name='price'
                          disabled={true}
                          value={formik.values.price}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>{' '}
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button
                  disabled={!formik.isValid || formik.isSubmitting}
                  type='submit'
                  onClick={formik.handleSubmit}
                  className='btn  btn-primary '
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  )
}

export {EditCompanyPackagesPage}
