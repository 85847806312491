import Swal from 'sweetalert2'
import {useNavigate, useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Loader} from '../components'
import GeneralService from '../service/general'

const EditDeviceTagPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const {data:models} = useQuery('DataType', async () =>
    GeneralService.getAll('DataType', {
      page: 1,
      perPage: 1000,
    })
  )
  const {data: sourceTypes} = useQuery('PackageDevice', async () =>
    GeneralService.getAll('PackageDevice', {
      page: 1,
      perPage: 1000,
    })
  )
  const {data, isLoading} = useQuery(['PackageDeviceTag', params.id], async () =>
    GeneralService.get('PackageDeviceTag', params.id)
  )

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      description: data?.description || '',
      address: data?.address || '',
      packageDeviceId: data?.packageDevice?.id || '',
      dataTypeId: data?.dataType?.id || '',
    },
    enableReinitialize: true,
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      dataTypeId: Yup.string().required('Provider zorunludur'),
      packageDeviceId: Yup.string().required('Provider zorunludur'),
      name: Yup.string().required('Ad zorunludur'),
      description: Yup.string(),
      address: Yup.string().required('Adres zorunludur'),
    }),
    onSubmit: async (values) => {
      await GeneralService.update('PackageDeviceTag', values, params.id)
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Başarıyla düzenlendi.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
    },
  })
  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate('/device-tag')} className='btn btn-primary'>
          Back
        </button>
        {isLoading ? (
          <div className='card-body p-9'>
            <Loader />
          </div>
        ) : (
          <div id='kt_account_profile_details' className='collapse show'>
            <form noValidate className='form'>
              <div className='card-body p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Data Type</label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <select
                          value={formik.values.dataTypeId}
                          onChange={formik.handleChange}
                          name='dataTypeId'
                          className='form-select form-control-solid'
                        >
                          <option selected>Data Type</option>
                          {models?.items?.map((item) => (
                            <option selected={data?.dataType?.id === item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Package Device</label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <select
                          value={formik.values.packageDeviceId}
                          onChange={formik.handleChange}
                          name='packageDeviceId'
                          className='form-select form-control-solid'
                        >
                          <option selected>Package Device</option>
                          {sourceTypes?.items?.map((item) => (
                            <option selected={data?.packageDevice?.id === item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>


                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>Ad</label>
                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Ad'
                          name='name'
                          value={formik.values.name}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>Aciklama</label>
                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Aciklama'
                          name='description'
                          value={formik.values.description}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>Adres</label>
                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Adres'
                          name='address'
                          value={formik.values.address}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>



              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button
                  disabled={!formik.isValid || formik.isSubmitting}
                  type='submit'
                  onClick={formik.handleSubmit}
                  className='btn  btn-primary '
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  )
}

export {EditDeviceTagPage}
