import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import LicenceService from '../service/licence'

// "licenceKey": "string",
//   "packageId": 0,
//   "companyId": 0,
//   "licenceStartDate": "2024-03-22T13:12:10.631Z",
//   "licenceEndDate": "2024-03-22T13:12:10.631Z",
//   "periodId": 0,
//   "macAddress": "string",
//   "mbserial": "string"

const AddLicencePage = () => {
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            licenceKey: '',
            packageId: 0,
            companyId: 0,
            licenceStartDate: 0,
            licenceEndDate: 0,
            periodId: 0,
            macAddress: '',
            mbserial:''
        },
        isInitialValid: false,
        validationSchema: Yup.object().shape({
            licenceKey: Yup.string().required('licenceKey is required'),
            packageId: Yup.mixed().nullable(),
            companyId: Yup.mixed().nullable(),
            licenceStartDate: Yup.mixed().nullable(),
            licenceEndDate: Yup.mixed().nullable(),
            periodId: Yup.mixed().nullable(),
            macAddress: Yup.string().nullable(),
            mbserial: Yup.string().nullable(),
        }),
        onSubmit: async (values) => {
            await LicenceService.create(values)
            Swal.fire({
                type: 'success',
                title: 'Başarılı',
                text: 'Package başarıyla oluşturuldu.',
                showConfirmButton: false,
                timer: 1500,
                icon: 'success',
            })
            navigate('/licence')
        },
    })

    return (
        <div className='card'>
            <div className='card-body p-4'>
                <button onClick={() => navigate('/licence')} className='btn btn-primary'>
                    Back
                </button>

                <div id='kt_account_profile_details' className='collapse show'>
                    <form noValidate className='form'>
                        <div className='card-body p-9'>


                            <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                                Licence Key
                                </label>

                                <div className='col-lg-9'>
                                    <div className='row'>
                                        <div className='col-lg-12 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                placeholder='Licence Key'
                                                name='licenceKey'
                                                value={formik.values.licenceKey}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                                Package Id
                                </label>

                                <div className='col-lg-9'>
                                    <div className='row'>
                                        <div className='col-lg-12 fv-row'>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                placeholder='Package Id'
                                                name='packageId'
                                                value={formik.values.packageId}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                                    Company Id
                                </label>

                                <div className='col-lg-9'>
                                    <div className='row'>
                                        <div className='col-lg-12 fv-row'>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                placeholder='Company Id'
                                                name='companyId'
                                                value={formik.values.companyId}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                                Licence Start Date
                                </label>

                                <div className='col-lg-9'>
                                    <div className='row'>
                                        <div className='col-lg-12 fv-row'>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                placeholder='Licence Start Date'
                                                name='licenceStartDate'
                                                value={formik.values.licenceStartDate}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div> <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                                Licence End Date
                                </label>
                                <div className='col-lg-9'>
                                    <div className='row'>
                                        <div className='col-lg-12 fv-row'>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                placeholder='Licence End Date'
                                                name='licenceEndDate'
                                                value={formik.values.licenceEndDate}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div> <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                               Period Id
                                </label>

                                <div className='col-lg-9'>
                                    <div className='row'>
                                        <div className='col-lg-12 fv-row'>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                placeholder='Period Id'
                                                name='periodId'
                                                value={formik.values.periodId}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                                    Mac Address
                                </label>

                                <div className='col-lg-9'>
                                    <div className='row'>
                                        <div className='col-lg-12 fv-row'>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                placeholder='Mac Address'
                                                name='macAddress'
                                                value={formik.values.macAddress}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                                    Mb Serial
                                </label>

                                <div className='col-lg-9'>
                                    <div className='row'>
                                        <div className='col-lg-12 fv-row'>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                placeholder='Mb Serial'
                                                name='mbserial'
                                                value={formik.values.mbserial}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button
                                disabled={!formik.isValid || formik.isSubmitting}
                                type='submit'
                                onClick={formik.handleSubmit}
                                className='btn  btn-primary '
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export { AddLicencePage }
