/* eslint-disable jsx-a11y/anchor-is-valid */
import { PageTitle } from '../_metronic/layout/core'
import CategoryManagementTable from '../components/CategoryManagementTable'

const CategoryPage = () => {

  return (
    <>
      <PageTitle breadcrumbs={[]}>Kategori Yönetimi</PageTitle>
      <CategoryManagementTable />
    </>
  )
}

export {CategoryPage}
