import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import SourceTypeService from '../service/sourceType'

const SourceTypeAddPage = () => {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      parentId: null,
    },
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Source Type name is required'),
      description: Yup.string().nullable(),
      parentId: Yup.mixed().nullable(),
    }),
    onSubmit: async (values) => {
      await SourceTypeService.create(values)
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Source Type başarıyla oluşturuldu.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
      navigate('/source-type')
    },
  })

  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate('/source-type')} className='btn btn-primary'>
          Back
        </button>

        <div id='kt_account_profile_details' className='collapse show'>
          <form noValidate className='form'>
            <div className='card-body p-9'>
            

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  Source Type Adı
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Source Type Adı'
                        name='name'
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                disabled={!formik.isValid || formik.isSubmitting}
                type='submit'
                onClick={formik.handleSubmit}
                className='btn  btn-primary '
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export {SourceTypeAddPage}
