import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Loader } from '../components'
import LicenceService from '../service/licence'

const EditLicencePage = () => {
    const navigate = useNavigate()
    const params = useParams()

    const { data, isLoading } = useQuery(['licence', params.id], async () =>
    LicenceService.get(params.id)
    )

    const formik = useFormik({
        initialValues: {
            name: data?.name ?? '',
            tagLimit: data?.tagLimit ?? '',
            deviceLimit: data?.deviceLimit ?? '',
            reportCreatorUserLimit: data?.reportCreatorUserLimit ?? '',
            reportViewerUserLimit: data?.reportViewerUserLimit ?? '',
            dataStorageSize: data?.dataStorageSize ?? '',
            price: data?.price ?? '',
        },
        enableReinitialize: true,
        isInitialValid: false,
        validationSchema: Yup.object().shape({
            name: Yup.string().required('licence name is required'),
            tagLimit: Yup.string().required('Tag limit is required'),
            deviceLimit: Yup.mixed().nullable(),
            reportCreatorUserLimit: Yup.mixed().nullable(),
            reportViewerUserLimit: Yup.mixed().nullable(),
            dataStorageSize: Yup.mixed().nullable(),
            price: Yup.mixed().nullable(),
        }),
        onSubmit: async (values) => {
            await LicenceService.update(values, params.id)
            Swal.fire({
                type: 'success',
                title: 'Başarılı',
                text: 'licence alanı başarıyla düzenlendi.',
                showConfirmButton: false,
                timer: 1500,
                icon: 'success',
            })
        },
    })
    return (
        <div className='card'>
            <div className='card-body p-4'>
                <button onClick={() => navigate('/licence')} className='btn btn-primary'>
                    Back
                </button>
                {isLoading ? (
                    <div className='card-body p-9'>
                        <Loader />
                    </div>
                ) : (
                    <div id='kt_account_profile_details' className='collapse show'>
                        <form noValidate className='form'>
                            <div className='card-body p-9'>


                                <div className='row mb-6'>
                                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                                    licence Alanı Adı
                                    </label>

                                    <div className='col-lg-9'>
                                        <div className='row'>
                                            <div className='col-lg-12 fv-row'>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                    placeholder='licence Alanı Adı'
                                                    name='name'
                                                    value={formik.values.name}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                                            Tag Limit
                                        </label>

                                        <div className='col-lg-9'>
                                            <div className='row'>
                                                <div className='col-lg-12 fv-row'>
                                                    <input
                                                        type='number'
                                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                        placeholder='Tag Limit'
                                                        name='tagLimit'
                                                        value={formik.values.tagLimit}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                                            Device Limit
                                        </label>

                                        <div className='col-lg-9'>
                                            <div className='row'>
                                                <div className='col-lg-12 fv-row'>
                                                    <input
                                                        type='number'
                                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                        placeholder='Device Limit'
                                                        name='deviceLimit'
                                                        value={formik.values.deviceLimit}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                                            Report Creator User Limit
                                        </label>

                                        <div className='col-lg-9'>
                                            <div className='row'>
                                                <div className='col-lg-12 fv-row'>
                                                    <input
                                                        type='number'
                                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                        placeholder='Device Limit'
                                                        name='reportCreatorUserLimit'
                                                        value={formik.values.reportCreatorUserLimit}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div> <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                                            Report Viewer User Limit
                                        </label>
                                        <div className='col-lg-9'>
                                            <div className='row'>
                                                <div className='col-lg-12 fv-row'>
                                                    <input
                                                        type='number'
                                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                        placeholder='Report Viewer User Limit'
                                                        name='reportViewerUserLimit'
                                                        value={formik.values.reportViewerUserLimit}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div> <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                                            Data Storage Size
                                        </label>

                                        <div className='col-lg-9'>
                                            <div className='row'>
                                                <div className='col-lg-12 fv-row'>
                                                    <input
                                                        type='number'
                                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                        placeholder='Data Storage Size'
                                                        name='dataStorageSize'
                                                        value={formik.values.dataStorageSize}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div> <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                                            Price
                                        </label>

                                        <div className='col-lg-9'>
                                            <div className='row'>
                                                <div className='col-lg-12 fv-row'>
                                                    <input
                                                        type='number'
                                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                        placeholder='Price'
                                                        name='price'
                                                        value={formik.values.price}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button
                                    disabled={!formik.isValid || formik.isSubmitting}
                                    type='submit'
                                    onClick={formik.handleSubmit}
                                    className='btn  btn-primary '
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </div>
    )
}

export { EditLicencePage }
