import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {useState} from 'react'
import {useQuery} from 'react-query'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import CategoryService from '../service/category'

const AddNewCategoryPage = () => {
  const navigate = useNavigate()
  const [isMain, setIsMain] = useState(true)

  const {data} = useQuery('categories', async () =>
    CategoryService.getAll({
      page: 1,
      perPage: 1000,
    })
  )

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      parentId: null,
    },
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Category Name is required'),
      description: Yup.string().nullable(),
      parentId: Yup.mixed().nullable(),
    }),
    onSubmit: async (values) => {
      await CategoryService.createCategory(values)
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Saha alanı başarıyla oluşturuldu.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
      navigate('/category')
    },
  })

  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate('/category')} className='btn btn-primary'>
          Back
        </button>

        <div id='kt_account_profile_details' className='collapse show'>
          <form noValidate className='form'>
            <div className='card-body p-9'>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  Ana Saha Alanı?
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        name='isMain'
                        id=''
                        checked={isMain}
                        onChange={(e) => {
                          setIsMain(!isMain)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {!isMain && (
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Üst Saha Alanı</label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <select
                          value={formik.values.parentId}
                          onChange={formik.handleChange}
                          name='parentId'
                          className='form-select form-control-solid'
                        >
                          <option selected>Üst Saha Alanı</option>
                          {data?.items?.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  Saha Alanı Adı
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Saha Alanı Adı'
                        name='name'
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Açıklama</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Açıklama'
                        name='description'
                        value={formik.values.description}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                disabled={!formik.isValid || formik.isSubmitting}
                type='submit'
                onClick={formik.handleSubmit}
                className='btn  btn-primary '
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export {AddNewCategoryPage}
