import axios from './index'

const getAll = async (params) => {
  try {
    const res = await axios.get('/Package', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}

const update = async (data, id) => {
  const res = await axios.put(`/Package/${id}`, data)
  return res?.data
}

const get = async (id) => {
  const res = await axios.get(`/Package/${id}`)
  return res?.data?.value
}

const remove = async (id) => {
  const res = await axios.delete(`/Package/${id}`)
  return res?.data
}

const create = async (data) => {
  const res = await axios.post(`/Package`, data)
  return res?.data
}

const PackageService = {
  getAll,
  get,
  update,
  create,
  remove
}

export default PackageService
