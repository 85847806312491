import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import SourceDataKeyService from '../service/sourceDataKey'
import {useQuery} from 'react-query'
import {PageTitle} from '../_metronic/layout/core'
import GeneralService from '../service/general'

const AddSourceDataKeyValuePage = () => {
  const navigate = useNavigate()

  const {data: sourceData} = useQuery(['source-data-key'], async () =>
    SourceDataKeyService.getAll({
      page: 1,
      perPage: 1000,
    })
  )

  const formik = useFormik({
    initialValues: {
      value: '',
      sourceDataKeyId: null,
    },
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      sourceDataKeyId: Yup.string().required('sourceDataKeyId is required'),
      value: Yup.string().required('Source Datakey name is required'),
    }),
    onSubmit: async (values) => {
      await GeneralService.create('SourceDataKeyValue', values)
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Source Type başarıyla oluşturuldu.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
      navigate('/source-datakey-value')
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>Yeni Source Data Key Value Ekle</PageTitle>

      <div className='card'>
        <div className='card-body p-4'>
          <button onClick={() => navigate('/source-datakey-value')} className='btn btn-primary'>
            Back
          </button>

          <div id='kt_account_profile_details' className='collapse show'>
            <form noValidate className='form'>
              <div className='card-body p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>
                    Source Data Key Seç
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <select
                          value={formik.values.sourceDataKeyId}
                          onChange={formik.handleChange}
                          name='sourceDataKeyId'
                          className='form-select form-control-solid'
                        >
                          <option selected>Source Data Key</option>
                          {sourceData?.items?.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>Value</label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Value'
                          name='value'
                          value={formik.values.value}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button
                  disabled={!formik.isValid || formik.isSubmitting}
                  type='submit'
                  onClick={formik.handleSubmit}
                  className='btn  btn-primary '
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export {AddSourceDataKeyValuePage}
