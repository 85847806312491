import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Loader } from '../components'
import SourceDataKeyService from '../service/sourceDataKey'
import DataTypeService from '../service/dataType'
import SourceTypeService from '../service/sourceType'

const EditSourceDataKeyPage = () => {
  const navigate = useNavigate()
  const params = useParams()

  const { data, isLoading } = useQuery(['source-datakey', params.id], async () =>
    SourceDataKeyService.get(params.id)
  )
  const { data: dataType } = useQuery(['dataTypes'], async () =>
    DataTypeService.getAll({
      page: 1,
      perPage: 1000,
    })
  )

  const { data: allData, } = useQuery(['source-type'], async () =>
  SourceTypeService.getAll({
    page:1,
    perPage:1000,
  })
)
  const formik = useFormik({
    initialValues: {
      name: data?.name ?? '',
      regex: data?.regex ?? '',
      sourceTypeId: data?.sourceTypeId ?? '',
      dataTypeId: data?.dataTypeId ?? ''
    },
    enableReinitialize: true,
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Source Type Name is required'),
      regex: Yup.string(),
      sourceTypeId: Yup.string().required('Source Type Select is required'),
      dataTypeId: Yup.string().required('Data Type Select is required'),

    }),
    onSubmit: async (values) => {
      await SourceDataKeyService.update(values, params.id)
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Source DataKey alanı başarıyla düzenlendi.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
    },
  })
  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate('/source-datakey')} className='btn btn-primary'>
          Back
        </button>
        {isLoading ? (
          <div className='card-body p-9'>
            <Loader />
          </div>
        ) : (
          <div id='kt_account_profile_details' className='collapse show'>
            <form noValidate className='form'>
              <div className='card-body p-9'>


                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Source Type Seç</label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <select
                          value={formik.values.sourceTypeId}
                          onChange={formik.handleChange}
                          name='sourceTypeId'
                          className='form-select form-control-solid'
                        >
                          <option selected>Source Type Seç</option>
                          {allData?.items?.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Data Type seç</label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <select
                          value={formik.values.dataTypeId}
                          onChange={formik.handleChange}
                          name='dataTypeId'
                          className='form-select form-control-solid'
                        >
                          <option selected>Data Type seç</option>
                          {dataType?.items?.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Source Datakey Alanı Adı
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Source Type Alanı Adı'
                          name='name'
                          value={formik.values.name}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>
                    Source DataKey Regex
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Source DataKey Regex'
                          name='regex'
                          value={formik.values.regex}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button
                  disabled={!formik.isValid || formik.isSubmitting}
                  type='submit'
                  onClick={formik.handleSubmit}
                  className='btn  btn-primary '
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  )
}

export { EditSourceDataKeyPage }
