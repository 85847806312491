import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import PackageService from '../service/package'
import {useQuery} from 'react-query'
import GeneralService from '../service/general'

const AddPackagePage = () => {
  const navigate = useNavigate()

  const {data: tenants} = useQuery('Tenant', async () =>
    GeneralService.getAll('multi-tenancy/tenants', {
      page: 1,
      perPage: 1000,
    })
  )
  const formik = useFormik({
    initialValues: {
      tenantId: '',
      description: '',
      monthlyPrice: 0,
      yearlyPrice: 0,
      deviceCount: 0,
      discSpaceGb: 0,
      userCount: 0,
      tagCount: 0,
      flowCount: 0,
      dashboardCount: 0,
    },
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      monthlyPrice: Yup.number().required('Monthly Price is required'),
      yearlyPrice: Yup.number().required('Yearly Price is required'),
      deviceCount: Yup.number().required('Device Count is required'),
      discSpaceGb: Yup.number().required('Disc Space is required'),
      userCount: Yup.number().required('User Count is required'),
      tagCount: Yup.number().required('Tag Count is required'),
      flowCount: Yup.number().required('Flow Count is required'),
      dashboardCount: Yup.number().required('Dashboard Count is required'),
    }),
    onSubmit: async (values) => {
      await PackageService.create(values)
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Source Type başarıyla oluşturuldu.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
      navigate('/package')
    },
  })

  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate('/package')} className='btn btn-primary'>
          Back
        </button>

        <div id='kt_account_profile_details' className='collapse show'>
          <form noValidate className='form'>
            <div className='card-body p-9'>
              <div className='row'>
                <div className='row mb-6 col-md-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Package Adı
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg  mb-3 mb-lg-0'
                          placeholder='Package Adı'
                          name='name'
                          value={formik.values.name}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6 col-md-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Package Aciklamasi</label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg  mb-3 mb-lg-0'
                          placeholder='Package Aciklamasi'
                          name='description'
                          value={formik.values.description}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6 col-md-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Tenant</label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <select
                          value={formik.values.tenantId}
                          onChange={formik.handleChange}
                          name='tenantId'
                          className='form-select '
                        >
                          <option selected>Tenant</option>
                          {tenants?.items?.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6 col-md-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Monthly Price
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg  mb-3 mb-lg-0'
                          placeholder='Monthly Price'
                          name='monthlyPrice'
                          value={formik.values.monthlyPrice}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6 col-md-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Yearly Price
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg  mb-3 mb-lg-0'
                          placeholder='Yearly Price'
                          name='yearlyPrice'
                          value={formik.values.yearlyPrice}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6 col-md-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Device Count
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg  mb-3 mb-lg-0'
                          placeholder='Device Count'
                          name='deviceCount'
                          value={formik.values.deviceCount}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6 col-md-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Disc Space Gb
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg  mb-3 mb-lg-0'
                          placeholder='Disc Space Gb'
                          name='discSpaceGb'
                          value={formik.values.discSpaceGb}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6 col-md-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    User Count
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg  mb-3 mb-lg-0'
                          placeholder='User Count'
                          name='userCount'
                          value={formik.values.userCount}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6 col-md-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>Tag Count</label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg  mb-3 mb-lg-0'
                          placeholder='Tag Count'
                          name='tagCount'
                          value={formik.values.tagCount}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6 col-md-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Flow Count
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg  mb-3 mb-lg-0'
                          placeholder='Flow Count'
                          name='flowCount'
                          value={formik.values.flowCount}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6 col-md-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Dashboard Count
                  </label>

                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='number'
                          className='form-control form-control-lg  mb-3 mb-lg-0'
                          placeholder='Dashboard Count'
                          name='dashboardCount'
                          value={formik.values.dashboardCount}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                disabled={!formik.isValid || formik.isSubmitting}
                type='submit'
                onClick={formik.handleSubmit}
                className='btn  btn-primary '
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export {AddPackagePage}
