import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import SourceDataKeyService from '../service/sourceDataKey'
import { useQuery } from 'react-query'
import DataTypeService from '../service/dataType'
import SourceTypeService from '../service/sourceType'

const AddSourceDataKeyPage = () => {
  const navigate = useNavigate()

  const { data: sourceData, } = useQuery(['source-type'], async () =>
    SourceTypeService.getAll({
      page:1,
      perPage:1000,
    })
  )

  const { data:dataType } = useQuery(['dataTypes'], async () =>
    DataTypeService.getAll({
      page:1,
      perPage:1000,
    })
  )
  const formik = useFormik({
    initialValues: {
      name: '',
      regex: '',
      sourceTypeId: null,
      dataTypeId: null,
    },
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      sourceTypeId: Yup.string().required('Source Type selection is required'),
      dataTypeId: Yup.string().required('Datatype selection is required'),
      name: Yup.string().required('Source Datakey name is required'),
      regex: Yup.string()
    }),
    onSubmit: async (values) => {
      await SourceDataKeyService.create(values)
      Swal.fire({
        type: 'success',
        title: 'Başarılı',
        text: 'Source Type başarıyla oluşturuldu.',
        showConfirmButton: false,
        timer: 1500,
        icon: 'success',
      })
      navigate('/source-datakey')
    },
  })

  return (
    <div className='card'>
      <div className='card-body p-4'>
        <button onClick={() => navigate('/source-datakey')} className='btn btn-primary'>
          Back
        </button>

        <div id='kt_account_profile_details' className='collapse show'>
          <form noValidate className='form'>

            <div className='card-body p-9'>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Source Type Seç</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        value={formik.values.sourceTypeId}
                        onChange={formik.handleChange}
                        name='sourceTypeId'
                        className='form-select form-control-solid'
                      >
                        <option selected>Source Type Seç</option>
                        {sourceData?.items?.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Data Type seç</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <select
                        value={formik.values.dataTypeId}
                        onChange={formik.handleChange}
                        name='dataTypeId'
                        className='form-select form-control-solid'
                      >
                        <option selected>Data Type seç</option>
                        {dataType?.items?.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  Source DataKey Adı
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Source DataKey Adı'
                        name='name'
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  Source DataKey Regex
                </label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Source DataKey Regex'
                        name='regex'
                        value={formik.values.regex}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                disabled={!formik.isValid || formik.isSubmitting}
                type='submit'
                onClick={formik.handleSubmit}
                className='btn  btn-primary '
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export { AddSourceDataKeyPage }
