import axios from './index'

const getAll = async (params) => {
  try {
    const res = await axios.get('/device', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}
const getAllEdgeDevices = async (params) => {
  try {
    const res = await axios.get('/edgeDevice', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}

const create = async (data) => {
  const res = await axios.post(`/device`, data)
  return res?.data
}

const remove = async (id) => {
  const res = await axios.delete(`/device/${id}`)
  return res?.data
}

const getDeviceByEdgeDeviceId = async (params) => {
  try {
    const res = await axios.get('/device/GetDeviceByEdgeDeviceId', {
      params,
    })

    return res?.data?.value
  } catch (error) {}
}


const DeviceService = {
  getAll,
  getAllEdgeDevices,
  getDeviceByEdgeDeviceId,
  create,
  remove
}

export default DeviceService
