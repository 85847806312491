import axios from './index'

const getAll = async (endpoint, params) => {
  try {
    const res = await axios.get(`/${endpoint}`, {
      params,
    })

    return res?.data?.value || res?.data
  } catch (error) {}
}

const update = async (endpoint, data, id, extraConfigs = {}) => {
  const res = await axios.put(`/${endpoint}/${id}`, data, extraConfigs)
  return res?.data
}

const get = async (endpoint, id) => {
  const res = await axios.get(`/${endpoint}/${id}`)
  return res?.data?.value || res?.data
}

const remove = async (endpoint, id) => {
  const res = await axios.delete(`/${endpoint}/${id}`)
  return res?.data
}

const create = async (endpoint, data, extraConfigs = {}) => {
  const res = await axios.post(`/${endpoint}`, data, extraConfigs)
  return res?.data
}

const GeneralService = {
  getAll,
  get,
  update,
  create,
  remove,
}

export default GeneralService
