/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {
  CardsWidget17,
  CardsWidget20,
  EngageWidget10,
  ListsWidget26,
} from '../_metronic/partials/widgets'
import {PageTitle} from '../_metronic/layout/core'
import {toAbsoluteUrl} from '../_metronic/helpers'

const DashboardPage: FC = () => (
  <>
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='col-xxl-6'>
        <EngageWidget10 className='h-md-100' />
      </div>
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget20
          className='h-md-50 mb-5 mb-xl-10'
          description='Active Projects'
          color='#F1416C'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />
        <ListsWidget26 className='h-lg-50' />
      </div>

      <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
        <ListsWidget26 className='h-lg-50' />
      </div>
    </div>
  </>
)

const Dashboard: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {Dashboard}
